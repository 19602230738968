import dragonfly from './hotel-dragonfly.jpg';
import greyFriars from './hotel-grey-friars.jpg';
import holidayInn from './hotel-holiday-inn-colchester.jpg';
import marlborough from './hotel-malborough.jpg';
import milsoms from './hotel-milsoms.jpg';
import roseAndCrown from './hotel-rose-and-crown.jpg';
import stokeByNayland from './hotel-stoke-by-nayland.jpg';
import crown from './hotel-the-crown.jpg';
import idleWaters from './hotel-idle-waters.jpg';
import premierInnColchester from './hotel-premier-inn-colchester.jpg';
import talboothHouseAndSpa from './hotel-talbooth-house-and-spa.jpg';

const hotelImages = {
  dragonfly,
  greyFriars,
  holidayInn,
  milsoms,
  marlborough,
  roseAndCrown,
  stokeByNayland,
  crown,
  idleWaters,
  premierInnColchester,
  talboothHouseAndSpa
};

export default hotelImages;