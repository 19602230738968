import carousel_1 from './carousel_1.jpg';
import carousel_2 from './carousel_2.jpg';
import carousel_3 from './carousel_3.jpg';
import carousel_4 from './carousel_4.jpg';
import carousel_5 from './carousel_5.jpg';
import carousel_6 from './carousel_6.jpg';
import carousel_7 from './carousel_7.jpg';
import carousel_8 from './carousel_8.jpg';
import carousel_9 from './carousel_9.jpg';

const carouselImages = {
  carousel_1,
  carousel_2,
  carousel_3,
  carousel_4,
  carousel_5,
  carousel_6,
  carousel_7,
  carousel_8,
  carousel_9,
};

export default carouselImages;