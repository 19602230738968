import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StyledCtaLink, StyledCtaRouterLink } from '../Styles';
import { media } from '../Variables';

interface IStyledInfoColumnProps {
  hasCta?: boolean;
}

export const StyledInfoColumn = styled.div`
  width: 50%;
  padding: 0 15px;
  text-align: center;
  position: relative;
  padding-bottom: ${(props: IStyledInfoColumnProps) => props.hasCta ? '2rem' : 0}; // 32px

  & & {
    width: 100%;
    margin-bottom: 2rem;
  }

  ${StyledCtaLink},
  ${StyledCtaRouterLink} {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 80%;
    margin: 0 auto;
  }

  p {
    &:first-of-type {
      margin-top: 0;
    }
  }

  @media ${media.mobile} {
    padding: 0;
    width: 100%;
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
    ${StyledCtaLink},
    ${StyledCtaRouterLink} {
      position: static;
      transform: none;
    }
  }
`;

export const StyledInfoColumnHeading = styled.h3`
  svg {
    margin-right: 8px;
  }
`;

interface IStyledInfoColumnContent {
  verticalAlign?: boolean;
}

const StyledInfoColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props: IStyledInfoColumnContent) => props.verticalAlign ? 'center' : 'flex-start'};
  height: ${(props: IStyledInfoColumnContent) => props.verticalAlign ? '100%' : 'auto'};

  a {
    justify-self: flex-end;
  }
`;

interface IInfoColumnProps {
  children: React.ReactNode;
  heading?: string;
  faIcon?: any;
  verticalAlign?: boolean;
  linkText?: string;
  link?: string;
  routerLink?: string;
}

const InfoColumn = ({
    heading,
    faIcon,
    verticalAlign,
    children,
    linkText,
    link,
    routerLink
  }: IInfoColumnProps) => {
  return (
    <StyledInfoColumn hasCta={linkText && (link || routerLink) ? true : false}>
      { heading &&
        <React.Fragment>
          <StyledInfoColumnHeading>
            {faIcon && <FontAwesomeIcon icon={faIcon} />}
            {heading}
          </StyledInfoColumnHeading>
        </React.Fragment>
      }
      <StyledInfoColumnContent verticalAlign={verticalAlign}>
        {children}
        {link && linkText &&
          <StyledCtaLink href={link} target="_blank" rel="noreferrer">{linkText}</StyledCtaLink>
        }
        {routerLink && linkText &&
          <StyledCtaRouterLink to={routerLink}>{linkText}</StyledCtaRouterLink>
        }
      </StyledInfoColumnContent>
    </StyledInfoColumn>
  )
}

export default InfoColumn;