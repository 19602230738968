import axios from 'axios';
import styled from 'styled-components';
import { useForm } from '../../hooks/useForm';
import Question from '../Question/Question';
import { StyledCtaButton } from '../Styles';
import { IFindGuestsResponse } from '../RSVPForm/RSVPFinder';
import { ICancel } from './CancelFormWrapper';
import { IGuest } from '../RSVPForm/RSVPFormWrapper';

export const StyledCancelForm = styled.form`
  width: 100%;

  ${StyledCtaButton} {
    margin: 0 auto;
  }
`;

export interface ICancelFormStatus {
  /** API status of the RSVP finder form */
  state: 'ready' | 'complete' | 'loading' | 'error';

  /** Additional information, if necessary (e.g. error message) */
  message?: string;
};

interface ICancelFormProps {
  /** Fired when the request has been submitted */
  onSubmitRequest: Function;

  /** Fired when the request returns successfully */
  onSubmitSuccess: Function;

  /** Fired in instances of API returning an error */
  onError: Function;

  /** Loading state of form */
  isFetching: boolean;

  /** Assumed correct passcode used from previous form */
  passcode: string;

  /** Response from fetching the RSVP invites from the `/guests` endpoint */
  rsvp: IFindGuestsResponse;
}

/** Makes request to submit where they are staying */
const submitCancel = async (data: any, passcode: string) => {
  const baseUrl = process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost/api';
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  const payload = {
    ...data,
    passcode
  };
  return await axios.post(`${baseUrl}/rsvp`, payload, config);
}

const parseData = (data: ICancel, rsvp: any) => {  
  const parsedData: any = {
    id: rsvp.id,
    message: data.message,
  };

  const parsedGuests: any[] = [];
  rsvp.guests.forEach((guest: IGuest) => {
    const guestData = {
      id: guest.id,
      attending: 'no'
    }

    parsedGuests.push(guestData);
  });

  parsedData.guests = parsedGuests;

  return parsedData;
}

const CancelForm = (props: ICancelFormProps) => {
  const { onSubmitRequest, onSubmitSuccess, onError, isFetching, rsvp, passcode } = props;

  const { handleSubmit, handleChange, data } = useForm<ICancel>({
    validations: {},
    onSubmit: () => {
      const parsedData = parseData(data, rsvp);
      onSubmitRequest(parsedData);
      submitCancel(parsedData, passcode).then((response) => {
        if (response && response.status === 200) {
          onSubmitSuccess();
        } else {
          onError();
        }
      }).catch(error => {
        onError(error);
      });
    }
  });

  return (
  <StyledCancelForm onSubmit={handleSubmit}>
    <Question inputType='textarea'
      id='message'
      label='Want to leave a note for the couple? (optional)'
      value={data.message}
      onChange={handleChange('message')} />

    <StyledCtaButton
      type='submit'
      disabled={isFetching}
      isLoading={isFetching}>
        { isFetching ? 'Submitting' : 'Submit' }
    </StyledCtaButton>
  </StyledCancelForm>
  )
}

export default CancelForm;