import { ChangeEvent, FormEvent, useState } from "react";

type ErrorRecord<T> = Partial<Record<keyof T, string>>;

export const useForm = <T extends Record<keyof T, any> = {}>(options: any) => {
  const [data, setData] = useState(options?.initialValues || {});
  const [errors, setErrors] = useState<ErrorRecord<T>>({});

  const handleChange = (key: any, sanitizeFn?: Function) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = sanitizeFn ? sanitizeFn(e.target.value) : e.target.value;

    setData({
      ...data,
      [key]: value
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validations = options?.validations;
    if (validations) {
      let valid = true;
      const newErrors: any = {};
      for (const key in validations) {
        const value = data[key];
        const validation = validations[key];
        if (validation?.required?.value && !value) {
          if (!validation.required.hasOwnProperty('onlyIf') || validation.required.onlyIf()) {
            valid = false;
            newErrors[key] = validation?.required?.message;
          }
        }

        const pattern = validation?.pattern;
        if (
          pattern?.value &&
          !RegExp(pattern.value).test(value) &&
          value !== undefined
        ) {
          valid = false;
          newErrors[key] = pattern.message;
        }
        
        const isNumber = validation?.isNumber;       
        if (
          isNumber?.value &&
          isNaN(value) &&
          value !== undefined
        ) {
          valid = false;
          newErrors[key] = isNumber.message;
        }

        const custom = validation?.custom;
        if (custom?.isValid && !custom.isValid(value)) {
          valid = false;
          newErrors[key] = custom.message;
        }
      }

      if (!valid) {
        console.log(newErrors);
        
        setErrors(newErrors);
        return;
      }
    }

    setErrors({});

    if (options?.onSubmit) {
      options.onSubmit();
    }
  };

  return {
    data,
    handleChange,
    handleSubmit,
    errors
  };
};
