import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import { colours, media } from './Variables';
import cake from '../assets/images/monogram-bakewell.png';
import bakewellRule from '../assets/images/bakewell-rule.png';
import { StyledHeroContainer } from './Hero/Hero';
import { IRSVPFormStatus } from './RSVPForm/RSVPForm';

export const StyledContentHolder = styled.div`
  width: 95%;
  max-width: 900px;
  margin: 60px auto;
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  ${StyledHeroContainer} + & {
    margin-top: 20px;
  }

  @media ${media.mobile} {
    margin: 20px auto;
  }
`;

export const SROnlyStyle = css`
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
`;

export const StyledHr = styled.hr`
  width: 100%;
  border: 0;
  height: 30px;
  background: url(${bakewellRule}) no-repeat center;
  background-size: auto 100%;
  margin: 5px 0;
`;

export const CtaStyle = css`
  display: block;
  padding: 10px;
  background-color: ${colours.orange};
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  font-size: 0.938em; // 15px
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
  border: 0;
  font-family: inherit;

  &:last-child {
    margin-bottom: 0;
  }

  &:disabled {
    opacity: 0.7;
  }
`;

export const StyledCtaLink = styled.a`
  ${CtaStyle};
`;

export const StyledCtaRouterLink = styled(Link)`
  ${CtaStyle};
`;

interface IStyledCtaButtonProps {
  isLoading?: boolean;
};

export const StyledCtaButton = styled.button`
  ${CtaStyle};
  position: relative;
  transition: padding 0.5s ease;
  font-weight: bold;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: calc(50% - 20px);
    height: 0;
    width: 0;
    opacity: 0;
    background: url(${cake}) no-repeat center;
    background-size: cover;
    transition: all 0.5s ease;
  }

  ${(props: IStyledCtaButtonProps) => props.isLoading && css`
    padding-right: 40px;
    &:after {
      height: 40px;
      width: 40px;
      opacity: 1;
      animation: ${rotate} 2s infinite linear;
    }
  `}
`

const LinkStyle = css`
  color: ${colours.orange};
  font-weight: bold;
`;

export const StyledLink = styled.a`
  ${LinkStyle}
`;

export const StyledRouterLink = styled(Link)`
  ${LinkStyle};
`;

const questionLabelStyles = css`
  display: block;
  color: ${colours.orange};
  padding: 0;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1.125rem; // 18px
`;

export const StyledLabel = styled.label`
  ${questionLabelStyles};
`;

export const StyledLegend = styled.legend`
  ${questionLabelStyles};
`;

const textInputStyles = css`
  background: transparent;
  color: ${colours.orange};
  border: 0;
  border-bottom: 1px solid ${colours.orange};
  font-family: inherit;
  font-style: italic;
  margin-left: 1rem;
  font-size: 1.125rem; // 18px
  line-height: 1.5rem; // 24px
  padding: 0.25rem 0.5rem;
  width: 75%;

  &:focus {
    outline: 2px solid ${colours.orange};
  }

  &::placeholder {
    color: ${colours.orange};
  }

  @media ${media.mobile} {
    width: 100%;
    margin-left: 0;
  }
`;

export const StyledTextInput = styled.input`
  ${textInputStyles};

  ${props => props.type === 'number' && css`
    width: 50px;
    text-align: center;
  `};
`;

export const StyledTextarea = styled.textarea`
  ${textInputStyles};
  resize: none;
`;

export const StyledSelectInput = styled.select`
  ${textInputStyles};
`

export const StyledAnimatedTick = styled.svg`
  stroke: ${colours.green};
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;

  path {
    stroke-dasharray: 130;
    stroke-dashoffset: 130;
  }
`;

export const AnimatedTick = () => (
  <StyledAnimatedTick viewBox='0 0 52 52'>
    <path d='M14.1 27.2l7.1 7.2 16.7-16.8' />
  </StyledAnimatedTick>
);

export const StyledRadioInput = styled.input`
  + ${StyledLabel} {
    position: relative;
    padding-right: 25px;
    
    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: -2px;
      width: 20px;
      height: 20px;
      border: 1px solid ${colours.orange};
    }

    &:after {
      content: '';
      position: absolute;
      right: -9px;
      top: -9px;
      width: 40px;
      height: 40px;
      background: url(${cake}) no-repeat center;
      background-size: cover;
      transform-origin: center;
      transform: scale(1.3) rotate(90deg);
      opacity: 0;
      transition: all 0.2s ease;
    }

    @media ${media.mobileSmall} {
      padding-right: 0;
      padding-left: 25px;
      &:before {
        left: 0;
        right: auto;        
      }
      &:after {
        right: auto;
        left: -9px;
      }
    }
  }
  &:checked {
    + ${StyledLabel} {
      &:after {
      transform: scale(1) rotate(0);
        opacity: 1;
      }
    }
  }
`;

export const StyledQuestion = styled.div`
  margin-bottom: 1rem;
`;

export const StyledQuestionGroup = styled.fieldset`
  border: 0;
  padding: 0;
  margin: 0;
  display: flex;

  ${StyledQuestion} {
    padding-left: 1rem;
    display: inline-block;
    ${StyledLabel} {
      margin-bottom: 0;
    }
  }

  @media ${media.mobileSmall} {
    display: block;

    ${StyledQuestion} {
      padding-left: 0;
      display: block;
    }
  }
`;

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const StyledLoadingSpinner = styled.div`
  position: relative;
  width: 100%;
  height: 100px;

  &:after {
    content: "";  
    position: absolute;
    left: calc(50% - 50px);
    top: 0;
    width: 100px;
    height: 100px;
    background: url(${cake}) no-repeat center;
    background-size: cover;
    animation: ${rotate} 2s linear infinite;
  }
`;

export const StyledQuestionNote = styled.p`
  margin: 0 0 1rem 1rem;
  padding-right: 120px;

  ${media.mobile} {
    padding-right: 0;
  };
`;

export const StyledErrorMessage = styled.p`
  color: ${colours.fuschia};
`;

interface IStyledFormMessageProps {
  state?: IRSVPFormStatus['state']
};

export const StyledFormMessage = styled.p`
  font-size: 1.125rem;
  font-style: italic;
  text-align: center;

  ${(props: IStyledFormMessageProps) => props.state === 'error' && css`
    color: ${colours.fuschia};
  `};

  ${(props: IStyledFormMessageProps) => props.state === 'complete' && css`
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 2px;
  `};

  ${StyledLink},
  ${StyledRouterLink} {
    color: currentColor;
    font-weight: bold;
  }

  ${StyledCtaButton},
  ${StyledCtaRouterLink} {
    margin: 10px auto 0;
  }
`;
