import React from "react";
import styled from "styled-components";
import { AxiosError } from "axios";
import { StyledCtaButton, StyledFormMessage, StyledLink } from "../Styles";
import RSVPFinder, { IFindGuestsResponse, IRSVPFinderStatus } from "./RSVPFinder";
import RSVPForm, { IRSVPFormStatus } from "./RSVPForm";
import FunMessage from "../FunMessage/FunMessage";

type TAttending = 'no' | 'full' | 'reception only' | 'ceremony only';

export interface IRegistration {
  attending0: 'yes' | 'no';
  attending1: 'yes' | 'no';
  dietary0: string;
  dietary1: string;
  duration0: TAttending;
  duration1: TAttending;
  contactEmail: string;
  contactNumber: string;
  message: string;
  console: string;
  /** Optional extra message for the wedding hosts */
  funMessage: string;
}

export interface IGuest {
  /** Unique identifier for guest */
  id: string;

  /** Human name */
  name: string;
  
  /** Whether guest has responded with attending or not */
  attending?: TAttending;

  dietary?: string;
}

export interface ICompletedFormValues {
  /** Unique ID of RSVP invite */
  id: number;

  /** Guests associated with the invitation */
  guests: IGuest[];

  /** Guests' contact email address */
  contactEmail: string;

  /** Guests' contact number */
  contactNumber: string;

  /** Comments from the guests */
  message: string;

  /** Optional fun message from the guests */
  funMessage?: string;
}

const StyledRSVPFormWrapper = styled.div``;

interface IRSVPFormWrapperProps {}

interface IRSVPFormWrapperState {
  /** Flag for loading spinner */
  isFetching: boolean;

  /** Current status of API calls to find RSVP */
  rsvpFinderStatus: IRSVPFinderStatus;

  /** Current status of API calls to submit RSVP for  */
  rsvpFormStatus: IRSVPFormStatus;

  /** Passcode for API requests, stored so that users don't have to enter it twice in one journey */
  passcode: string;
  
  /** Whether to display an error message (i.e. there is a problem in the journey) */
  hasError: boolean;
  
  /** Error message to render if `hasError` is true */
  errorMessage: string;

  rsvp?: IFindGuestsResponse;

  /** Previously complete form to be passed to use in FunMessage payload */
  completedFormValues?: ICompletedFormValues;

  /** Flag for if the user has agreed to fill in the RSVP form again */
  isOverwriting: boolean;
}

enum ErrorMessages {
  Generic = `There was an error: `,
  NotFound = `There was a problem finding you, maybe we have you under a different name? Please use the name as seen on the envelope.`,
  NotAuthorized = `You have entered the incorrect password (tip: it's on the invitation!)`
}

class RSVPFormWrapper extends React.Component<IRSVPFormWrapperProps, IRSVPFormWrapperState> {
  constructor(props: IRSVPFormWrapperProps) {
    super(props);

    this.state = {
      isFetching: false,
      rsvpFinderStatus: {
        state: 'ready'
      },
      rsvpFormStatus: {
        state: 'ready'
      },
      passcode: '',
      hasError: false,
      errorMessage: '',
      isOverwriting: false
    };
  }

  /**
   * Sets loading state for specified state prop
   * 
   * @param completedFormValues completed form values
  */
 handleRequestData = (completedFormValues: ICompletedFormValues) => {
  this.setState({
    ...this.state,
    hasError: false,
    errorMessage: '',
    isFetching: true,
    completedFormValues
  });
 }

  /**
   * Handles the response from requesting the guests from the API
   * 
   * @param guests the guests found from the service
   * @param passcode assumed valid passcode used from RSVPFinder form, to pass onto RSVP form
  */
  handleGuests = (rsvp: IFindGuestsResponse, passcode: string) => {    
    this.setState({
      ...this.state,
      isFetching: false,
      hasError: false,
      errorMessage: '',
      rsvpFinderStatus: {
        state: 'complete'
      },
      rsvp,
      passcode
    });
  }

  /**
   * Handles error messages from either RSVP finder or RSVP form
   * 
   * @param form the form that has returned the error
   * @param message further details about error from API
  */
  handleError = (error?: string | AxiosError) => {
    let message;
    if (typeof error === 'string') {
      message = error;
    } else if (error && error.response) {      
      switch (error.response.status) {
        case 401:
          message = ErrorMessages.NotAuthorized;
          break;
        case 403:
          message = ErrorMessages.NotFound;
          break;
        default:
          message = `${ErrorMessages.Generic}${error.response.statusText}`;
      }
    }    
    this.setState({
      ...this.state,
      isFetching: false,
      hasError: true,
      errorMessage: message ? message : ErrorMessages.NotFound
    });
  }

  /**
   * Handles the response from submitting an RSVP
   * 
   * @param attending whether guests have set themselves as attending or not
  */
  handleSubmit = (attending: boolean) => {
    this.setState({
      ...this.state,
      isFetching: false,
      hasError: false,
      errorMessage: '',
      rsvpFormStatus: {
        state: 'complete',
        message: `Thank you! ${attending ? 'We can\'t wait to see you on the day!' : 'We\'re sorry you can\'t make it!'}`
      }
    });    
  }

  setOverwriting = () => {
    this.setState({
      ...this.state,
      isOverwriting: true
    });
  }

  render() {
    const { rsvpFinderStatus,
      rsvpFormStatus,
      rsvp,
      passcode,
      completedFormValues,
      isFetching,
      hasError,
      errorMessage,
      isOverwriting } = this.state;
    return (
      <StyledRSVPFormWrapper>
        { rsvpFinderStatus.state !== 'complete' &&
          <RSVPFinder
            onSubmitRequest={() => this.handleRequestData}
            onSubmitSuccess={this.handleGuests}
            onError={this.handleError}
            isFetching={isFetching} />
        }
        { rsvpFinderStatus.state === 'complete' && rsvpFormStatus.state !== 'complete' && rsvp &&
          <React.Fragment>
            { !rsvp.guests[0].attending || isOverwriting
              ?
              <RSVPForm
                onSubmitRequest={(completedFormValues: ICompletedFormValues) => this.handleRequestData(completedFormValues)}
                onSubmitSuccess={this.handleSubmit}
                onError={this.handleError}
                isFetching={isFetching}
                rsvp={rsvp}
                passcode={passcode} />
              :
              <React.Fragment>
                <StyledFormMessage>
                  Heads up! You have already submitted your RSVP! Do you want to submit another one? This will overwrite your previous submission.
                  <StyledCtaButton onClick={this.setOverwriting}>Yes please!</StyledCtaButton>
                </StyledFormMessage>
              </React.Fragment>
            }
          </React.Fragment>
        }
        { hasError &&
          <StyledFormMessage state='error'>
            {errorMessage} <br />
            If you keep seeing this, drop me an email at <StyledLink href='mailto:questions@bakewell.wedding'>questions@bakewell.wedding</StyledLink>
          </StyledFormMessage>
        }
        { rsvpFormStatus.state === 'complete' &&
          <React.Fragment>
            <StyledFormMessage state='complete'>{rsvpFormStatus.message}</StyledFormMessage>
            { completedFormValues &&
            <FunMessage
              completedFormValues={completedFormValues}
              passcode={passcode} />
            }
          </React.Fragment>
        }
      </StyledRSVPFormWrapper>
    );
  }
}

export default RSVPFormWrapper;