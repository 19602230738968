import React from "react";
import styled from "styled-components";
import { AxiosError } from "axios";
import { StyledCtaRouterLink, StyledFormMessage, StyledLink } from "../Styles";
import RSVPFinder, { IFindGuestsResponse, IRSVPFinderStatus } from "../RSVPForm/RSVPFinder";
import TaxiShareForm, { ITaxiShareFormStatus } from "./TaxiShareForm";

export interface IHotelStay {
  hotel: string;
}

const StyledTaxiShareFormWrapper = styled.div`
  p:first-child {
    margin-top: 0;
  }

  ${StyledFormMessage} {
    font-size: inherit;
    text-transform: none;

    ${StyledCtaRouterLink} {
      width: auto;
      display: inline-block;
    }
  }
`;

interface ITaxiShareFormWrapperProps {}

interface ITaxiShareFormWrapperState {
  /** Flag for loading spinner */
  isFetching: boolean;

  /** Current status of API calls to find RSVP */
  rsvpFinderStatus: IRSVPFinderStatus;

  /** Current status of API calls to submit RSVP for  */
  taxiShareFormStatus: ITaxiShareFormStatus;

  /** Passcode for API requests, stored so that users don't have to enter it twice in one journey */
  passcode: string;
  
  /** Whether to display an error message (i.e. there is a problem in the journey) */
  hasError: boolean;
  
  /** Error message to render if `hasError` is true */
  errorMessage: string;

  rsvp?: IFindGuestsResponse;
}

enum ErrorMessages {
  Generic = `There was an error: `,
  NotFound = `There was a problem finding you, maybe we have you under a different name? Please use the name as seen on the envelope.`,
  NotAuthorized = `You have entered the incorrect password (tip: it's on the invitation!)`
}

class TaxiShareFormWrapper extends React.Component<ITaxiShareFormWrapperProps, ITaxiShareFormWrapperState> {
  constructor(props: ITaxiShareFormWrapperProps) {
    super(props);

    this.state = {
      isFetching: false,
      rsvpFinderStatus: {
        state: 'ready'
      },
      taxiShareFormStatus: {
        state: 'ready'
      },
      passcode: '',
      hasError: false,
      errorMessage: ''
    };
  }

  /**
   * Sets loading state for specified state prop
   * 
   * @param completedFormValues completed form values
  */
 handleRequestData = () => {
  this.setState({
    ...this.state,
    hasError: false,
    errorMessage: '',
    isFetching: true
  });
 }

  /**
   * Handles the response from requesting the guests from the API
   * 
   * @param guests the guests found from the service
   * @param passcode assumed valid passcode used from RSVPFinder form, to pass onto RSVP form
  */
  handleGuests = (rsvp: IFindGuestsResponse, passcode: string) => {    
    this.setState({
      ...this.state,
      isFetching: false,
      hasError: false,
      errorMessage: '',
      rsvpFinderStatus: {
        state: 'complete'
      },
      rsvp,
      passcode
    });
  }

  /**
   * Handles error messages from either RSVP finder or RSVP form
   * 
   * @param form the form that has returned the error
   * @param message further details about error from API
  */
  handleError = (error?: string | AxiosError) => {
    let message;
    if (typeof error === 'string') {
      message = error;
    } else if (error && error.response) {      
      switch (error.response.status) {
        case 401:
          message = ErrorMessages.NotAuthorized;
          break;
        case 403:
          message = ErrorMessages.NotFound;
          break;
        default:
          message = `${ErrorMessages.Generic}${error.response.statusText}`;
      }
    }    
    this.setState({
      ...this.state,
      isFetching: false,
      hasError: true,
      errorMessage: message ? message : ErrorMessages.NotFound
    });
  }

  /**
   * Handles the response from submitting the form
  */
  handleSubmit = () => {
    this.setState({
      ...this.state,
      isFetching: false,
      hasError: false,
      errorMessage: '',
      taxiShareFormStatus: {
        state: 'complete',
        message: `Thank you! We'll let you know if someone else is staying at the same hotel!`
      }
    });    
  }

  render() {
    const { rsvpFinderStatus,
      taxiShareFormStatus,
      rsvp,
      passcode,
      isFetching,
      hasError,
      errorMessage } = this.state;
    return (
      <StyledTaxiShareFormWrapper>
        { rsvpFinderStatus.state !== 'complete' &&
        <React.Fragment>
          <p>Booked a hotel and fancy doing a taxi share? No problem, let us know what hotel you're staying at and we'll try to connect you to any other guests staying at the same hotel!</p>
          <RSVPFinder
            onSubmitRequest={() => this.handleRequestData}
            onSubmitSuccess={this.handleGuests}
            onError={this.handleError}
            isFetching={isFetching} />
          </React.Fragment>
        }
        { rsvpFinderStatus.state === 'complete' && taxiShareFormStatus.state !== 'complete' && rsvp &&
          <React.Fragment>
            { rsvp.guests[0].attending
              ?
              <TaxiShareForm
                onSubmitRequest={this.handleRequestData}
                onSubmitSuccess={this.handleSubmit}
                onError={this.handleError}
                isFetching={isFetching}
                rsvp={rsvp}
                passcode={passcode} />
              :
              <React.Fragment>
                <StyledFormMessage>
                  Hang on! You haven't RSVPd yet, come back when you have submitted an RSVP!<br />
                  <StyledCtaRouterLink to='/rsvp'>RSVP now</StyledCtaRouterLink>
                </StyledFormMessage>
              </React.Fragment>
            }
          </React.Fragment>
        }
        { hasError &&
          <StyledFormMessage state='error'>
            {errorMessage} <br />
            If you keep seeing this, drop me an email at <StyledLink href='mailto:questions@bakewell.wedding'>questions@bakewell.wedding</StyledLink>
          </StyledFormMessage>
        }
        { taxiShareFormStatus.state === 'complete' &&
          <React.Fragment>
            <StyledFormMessage state='complete'>{taxiShareFormStatus.message}</StyledFormMessage>
          </React.Fragment>
        }
      </StyledTaxiShareFormWrapper>
    );
  }
}

export default TaxiShareFormWrapper;