import { IImageCollection } from '../../../../components/ImageCollection/ImageCollection';

import img_engagement_1_600x800 from './engagement-1_600x800.jpg';
import img_engagement_2_2048x1367 from './engagement-2_2048x1367.jpg';
import img_engagement_3_1638x2048 from './engagement-3_1638x2048.jpg';
import img_engagement_4_1638x2048 from './engagement-4_1638x2048.jpg';
import img_engagement_5_1638x2048 from './engagement-5_1638x2048.jpg';
import img_engagement_6_1635x1635 from './engagement-6_1635x1635.jpg';
import img_engagement_7_1365x2048 from './engagement-7_1365x2048.jpg';
import img_engagement_8_2048x1365 from './engagement-8_2048x1365.jpg';
import img_engagement_9_1367x2048 from './engagement-9_1367x2048.jpg';
import img_engagement_10_795x1500 from './engagement-10_795x1500.jpg';
import img_engagement_11_1362x1363 from './engagement-11_1362x1363.jpg';
import img_engagement_12_1363x1362 from './engagement-12_1363x1362.jpg';
import img_engagement_13_1080x811 from './engagement-13_1080x811.jpeg';
import img_engagement_14_1080x1440 from './engagement-14_1080x1440.jpeg';
import img_engagement_15_1067x1600 from './engagement-15_1067x1600.jpeg';
import img_engagement_16_1067x1600 from './engagement-16_1067x1600.jpeg';
import img_engagement_17_1200x1600 from './engagement-17_1200x1600.jpeg';
import img_engagement_18_1600x1067 from './engagement-18_1600x1067.jpeg';


const images: IImageCollection[] = [
  {
    src: img_engagement_1_600x800,
    width: 600,
    height: 800
  },
  {
    src: img_engagement_2_2048x1367,
    width: 2048,
    height: 1367
  },
  {
    src: img_engagement_3_1638x2048,
    width: 1638,
    height: 2048
  },
  {
    src: img_engagement_4_1638x2048,
    width: 1638,
    height: 2048
  },
  {
    src: img_engagement_5_1638x2048,
    width: 1638,
    height: 2048
  },
  {
    src: img_engagement_6_1635x1635,
    width: 1635,
    height: 1635
  },
  {
    src: img_engagement_7_1365x2048,
    width: 1365,
    height: 2048
  },
  {
    src: img_engagement_8_2048x1365,
    width: 2048,
    height: 1365
  },
  {
    src: img_engagement_9_1367x2048,
    width: 1367,
    height: 2048
  },
  {
    src: img_engagement_10_795x1500,
    width: 795,
    height: 1500
  },
  {
    src: img_engagement_11_1362x1363,
    width: 1362,
    height: 1363
  },
  {
    src: img_engagement_12_1363x1362,
    width: 1363,
    height: 1362
  },
  {
    src: img_engagement_13_1080x811,
    width: 1080,
    height: 811
  },
  {
    src: img_engagement_14_1080x1440,
    width: 1080,
    height: 1440
  },
  {
    src: img_engagement_15_1067x1600,
    width: 1067,
    height: 1600
  },
  {
    src: img_engagement_16_1067x1600,
    width: 1067,
    height: 1600
  },
  {
    src: img_engagement_17_1200x1600,
    width: 1200,
    height: 1600
  },
  {
    src: img_engagement_18_1600x1067,
    width: 1600,
    height: 1067
  },
];

export default images;
