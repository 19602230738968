import styled from 'styled-components';
import { StyledContentHolder } from '../Styles';
import { media } from '../Variables';

export const StyledInfoSection = styled(StyledContentHolder)``;

const StyledInfoSectionHeading = styled.h2``;

export const StyledInfoSectionContent = styled.div``;

export const StyledInfoSectionRow = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media ${media.mobile} {
    display: block;
  }
`;

interface IInfoSectionProps {
  children: React.ReactNode;
  id?: string;
  heading?: string;
}

const InfoSection = ({children, id, heading}: IInfoSectionProps) => {
  return (
    <StyledInfoSection id={id}>
      {heading && <StyledInfoSectionHeading>{heading}</StyledInfoSectionHeading>}
      <StyledInfoSectionContent>
        {children}
      </StyledInfoSectionContent>
    </StyledInfoSection>
  )
}

export default InfoSection;