import styled from 'styled-components';
import Select, { MultiValue } from 'react-select';
import { useState } from 'react';
import PhotoAlbum, { Photo } from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

export interface IImageCollection {
  src: string;
  width: number;
  height: number;
  tags?: string[],
  srcSet?: {
    src: string;
    width: number;
    height: number
  }[];
}

const generateGalleryProp = (imageCollection: IImageCollection[]) => {
  return imageCollection.map(image => ({
    src: image.src,
    width: image.width,
    height: image.height,
    srcSet: image.srcSet
  }));  
}

const StyledImageCollectionContainer = styled.div``

const StyledImageFilter = styled.div`
  margin-bottom: 10px;
`;

interface IImageCollectionProps {
  title: string;
  photos: IImageCollection[];
  id?: string;
  showFilter?: boolean;
}

export const ImageCollection = ({ title, photos, id, showFilter }: IImageCollectionProps) => {
  const [images, setImages] = useState<Photo[]>(generateGalleryProp(photos));
  const [galleryIndex, setGalleryIndex] = useState(-1);
  const [filter, setFilter] = useState<string[]>([]);

  const filterOptions = [
    { value: 'bride', label: 'Bride', },
    { value: 'groom', label: 'Groom', },
    { value: 'bridesmaids', label: 'Bridesmaids', },
    { value: 'groomsmen', label: 'Groomsmen', },
    { value: 'fatherOfTheBride', label: 'Father of the bride', },
    { value: 'motherOfTheBride', label: 'Mother of the bride', },
    { value: 'fatherOfTheGroom', label: 'Father of the groom', },
    { value: 'motherOfTheGroom', label: 'Mother of the groom', },
    { value: 'detail', label: 'Detail', },
    { value: 'car', label: 'Car', },
    { value: 'flowers', label: 'Flowers', },
    { value: 'cake', label: 'Cake', },
    { value: 'rings', label: 'Rings', },
    { value: 'boat', label: 'Boat', },
    { value: 'gettingReady', label: 'Getting ready', },
    { value: 'ceremony', label: 'Ceremony', },
    { value: 'confetti', label: 'Confetti', },
    { value: 'drinksReception', label: 'Drinks reception', },
    { value: 'group', label: 'Group shot', },
    { value: 'breakfast', label: 'Breakfast', },
    { value: 'singingWaiters', label: 'Singing waiters', },
    { value: 'reception', label: 'Reception', },
    { value: 'speeches', label: 'Speeches', },
    { value: 'goldenhour', label: 'Golden hour', },
    { value: 'firstDance', label: 'First dance', },
    { value: 'bouquetToss', label: 'Bouquet toss', },
    { value: 'saxophone', label: 'Saxophone' },
    { value: 'photobooth', label: 'Photobooth' },
  ]

  /**
   * Updates the image filter and filters out images based on relevant tags
   */
  const handleFilterChange = (newValue: MultiValue<{ value: string; label: string; }>) => {
    let newFilter: string[] = newValue.map(selected => selected.value);

    setFilter(newFilter);

    if (newFilter.length <= 0) {
      setImages(generateGalleryProp(photos));
    } else {
      setImages(
        generateGalleryProp(
          photos.filter(photo =>
            newFilter.every(filterTag => photo.tags?.includes(filterTag))
          )
        )
      )
    }
  }
  
  return (
    <>
      <StyledImageCollectionContainer id={id}>
        <h2>{title}</h2>
        { showFilter &&
          <StyledImageFilter>
            <label htmlFor="galleryFilter">Filter:</label>
            <Select
              options={filterOptions}
              onChange={handleFilterChange}
              isMulti={true}
              inputId='galleryFilter' />
          </StyledImageFilter>
        }
        { images.length > 0 ? (
          <PhotoAlbum
            layout='rows'
            photos={images}
            onClick={({ index }) => setGalleryIndex(index)} />
        ) : (
          <>
            { showFilter && (
              <p>No images for that combination found, try fewer filters!</p>
            )}
          </>
        )}
      </StyledImageCollectionContainer>
      <Lightbox
        slides={images}
        open={galleryIndex >= 0}
        index={galleryIndex}
        close={() => setGalleryIndex(-1)}
        plugins={[Thumbnails, Zoom]} />
    </>
  )
}

export default ImageCollection;