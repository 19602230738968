
import axios from 'axios';
import React from 'react';
import styled from 'styled-components';
import { useForm } from '../../hooks/useForm';
import Question from '../Question/Question';
import {
  StyledCtaButton, StyledErrorMessage, StyledLabel,
  StyledLegend, StyledQuestion,
  StyledQuestionGroup, StyledQuestionNote, StyledRadioInput
} from '../Styles';
import { IFindGuestsResponse } from './RSVPFinder';
import { IGuest, IRegistration } from './RSVPFormWrapper';

export const StyledRSVPForm = styled.form`
  width: 100%;

  ${StyledCtaButton} {
    margin: 0 auto;
  }
`;

export interface IRSVPFormStatus {
  /** API status of the RSVP finder form */
  state: 'ready' | 'complete' | 'loading' | 'error';

  /** Additional information, if necessary (e.g. error message) */
  message?: string;
};

interface IRSVPFormProps {
  /** Fired when the request has been submitted */
  onSubmitRequest: Function;

  /** Fired when the request returns successfully */
  onSubmitSuccess: Function;

  /** Fired in instances of API returning an error */
  onError: Function;

  /** Loading state of form */
  isFetching: boolean;

  /** Assumed correct passcode used from previous form */
  passcode: string;

  /** Response from fetching the RSVP invites from the `/guests` endpoint */
  rsvp: IFindGuestsResponse;
}

/** Makes request to submit RSVP */
const submitRSVP = async (data: any, passcode: string) => {
  const baseUrl = process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost/api';
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  const payload = {
    ...data,
    passcode
  };
  return await axios.post(`${baseUrl}/rsvp`, payload, config);
}

/** Crudely sellotape the data together using a combination of the form data and the previously sumbitted RSVP */
const parseData = (data: IRegistration, rsvp: any) => {  
  const parsedData: any = {
    id: rsvp.id,
    contactEmail: data.contactEmail,
    contactNumber: data.contactNumber,
    console: data.console,
    message: data.message,
    funMessage: data.funMessage
  };

  const parsedGuests: any[] = [];
  rsvp.guests.forEach((guest: IGuest, index: number) => {
    const guestData = {
      id: guest.id,
      dietary: index === 0 ? data.dietary0 : data.dietary1,
      attending: index === 0 ?
        data.attending0 === 'no' ? 'no' : data.duration0 :
        data.attending1 === 'no' ? 'no' : data.duration1
    }
    
    if (rsvp.receptionOnly) {
      guestData.attending = index === 0 ?
        data.attending0 === 'no' ? 'no' : 'reception only' :
        data.attending1 === 'no' ? 'no' : 'reception only'
    };

    parsedGuests.push(guestData);
  });

  parsedData.guests = parsedGuests;

  return parsedData;
}

const RSVPForm = (props: IRSVPFormProps) => {
  const { onSubmitRequest, onSubmitSuccess, onError, isFetching, rsvp, passcode } = props;

  const { handleSubmit, handleChange, errors, data } = useForm<IRegistration>({
    validations: {
      contactEmail: {
        required: {
          onlyIf: () => data.attending0 === 'yes' || data.attending1 === 'yes',
          value: true,
          message: 'This field is required'
        },
        pattern: {
          value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: 'Please enter a valid email address'
        }
      },
      contactNumber: {
        required: {
          onlyIf: () => data.attending0 === 'yes' || data.attending1 === 'yes',
          value: true,
          message: 'This field is required'
        },
        isNumber: {
          value: true,
          message: 'Please enter a valid phone number'
        }
      }
    },
    onSubmit: () => {
      const parsedData = parseData(data, rsvp);
      onSubmitRequest(parsedData);
      submitRSVP(parsedData, passcode).then((response) => {
        if (response && response.data && response.data.guests.length > 0) {
          onSubmitSuccess(data.attending0 === 'yes' || data.attending1 === 'yes');
        } else {
          onError();
        }
      }).catch(error => {
        onError(error);
      });
    }
  });

  return (
  <StyledRSVPForm onSubmit={handleSubmit}>
    { rsvp.guests.map((guest: IGuest, index) =>
      <div key={index}>
        <StyledQuestionGroup>
          <StyledLegend>{guest.name}, can you make it? (required)</StyledLegend>
          <StyledQuestion>
            <StyledRadioInput
              id={`attendingYes${index}`}
              className='sr-only'
              name={`attending${guest.id}`}
              type='radio'
              required
              aria-required='true'
              value='yes'
              onChange={handleChange(`attending${index}`)} />
            <StyledLabel htmlFor={`attendingYes${index}`}>
              Yes
            </StyledLabel>
          </StyledQuestion>

          <StyledQuestion>
            <StyledRadioInput
              id={`attendingNo${index}`}
              className='sr-only'
              name={`attending${guest.id}`}
              type='radio'
              required
              aria-required='true'
              value='no'
              onChange={handleChange(`attending${index}`)} />
            <StyledLabel htmlFor={`attendingNo${index}`}>
              No
            </StyledLabel>
          </StyledQuestion>
          { index === 0 && errors.attending0 && <StyledErrorMessage>{errors.attending0}</StyledErrorMessage> }
          { index === 1 && errors.attending1 && <StyledErrorMessage>{errors.attending1}</StyledErrorMessage> }
        </StyledQuestionGroup>
        
        { data[`attending${index}`] === 'yes' && 
          <React.Fragment>          
          { !rsvp.receptionOnly &&
            <React.Fragment>
              <StyledQuestionGroup>
                <StyledLegend>When can you join us? (required)</StyledLegend>
                <StyledQuestion>
                  <StyledRadioInput
                    id={`fullDuration${index}`}
                    className='sr-only'
                    name={`duration${guest.id}`}
                    type='radio'
                    required
                    aria-required='true'
                    value='full'
                    onChange={handleChange(`duration${index}`)} />
                  <StyledLabel htmlFor={`fullDuration${index}`}>
                    All day *
                  </StyledLabel>
                </StyledQuestion>

                <StyledQuestion>
                  <StyledRadioInput
                    id={`durationCeremony${index}`}
                    className='sr-only'
                    name={`duration${guest.id}`}
                    type='radio'
                    required
                    aria-required='true'
                    value='ceremony only'
                    onChange={handleChange(`duration${index}`)} />
                    <StyledLabel htmlFor={`durationCeremony${index}`}>
                    Ceremony and cocktails
                  </StyledLabel>
                </StyledQuestion>

                <StyledQuestion>
                  <StyledRadioInput
                    id={`durationReception${index}`}
                    className='sr-only'
                    name={`duration${guest.id}`}
                    type='radio'
                    required
                    aria-required='true'
                    value='reception only'
                    onChange={handleChange(`duration${index}`)} />
                    <StyledLabel htmlFor={`durationReception${index}`}>
                    Evening reception
                  </StyledLabel>
                </StyledQuestion>
              </StyledQuestionGroup>
              <StyledQuestionNote>* includes the wedding breakfast</StyledQuestionNote>
              { index === 0 && errors.duration0 && <StyledErrorMessage>{errors.duration0}</StyledErrorMessage> }
              { index === 1 && errors.duration1 && <StyledErrorMessage>{errors.duration1}</StyledErrorMessage> }
            </React.Fragment>
          }          
          <Question inputType='text'
            id='dietary'
            label='Do you have dietary requirements?'
            placeholder='e.g. vegetarian, gluten free, no alcohol'
            value={data[`dietary${index}`]}
            onChange={handleChange(`dietary${index}`)} />
          </React.Fragment>
        }
      </div>
    )}

    { (data.attending0 === 'yes' || data.attending1 === 'yes') &&
      <React.Fragment>
        <Question inputType='text'
          id='contactEmail'
          label='Contact email address'
          required={true}
          value={data.contactEmail}
          onChange={handleChange('contactEmail')}
          errors={errors.contactEmail} />

        <Question inputType='text'
          id='contactNumber'
          label='Contact number'
          required={true}
          value={data.contactNumber}
          onChange={handleChange('contactNumber')}
          errors={errors.contactNumber} />
        <StyledQuestionNote>Don't worry, we'll only use your details in case we need to contact you about the day!</StyledQuestionNote>

        <Question inputType='select'
          id='console'
          label="What's your all time favourite games console?"
          value={data.console}
          onChange={handleChange('console')}
          selectOptions={[
            { label: '', value: '' },
            { label: 'Atari 2600', value: 'Atari2600' },
            { label: 'Nintendo Entertainment System (NES)', value: 'NES' },
            { label: 'Sega Megadrive', value: 'Megadrive' },
            { label: 'Super Nintendo Entertainment System (SNES)', value: 'SNES' },
            { label: 'Sega Saturn', value: 'Saturn' },
            { label: 'Sony Playstation', value: 'PS1' },
            { label: 'Nintendo N64', value: 'N64' },
            { label: 'Sega Dreamcast', value: 'Dreamcast' },
            { label: 'Playstation 2', value: 'PS2' },
            { label: 'Nintendo Gamecube', value: 'Gamecube' },
            { label: 'Microsoft Xbox', value: 'Xbox' },
            { label: 'Nintendo Wii', value: 'Wii' },
            { label: 'Playstation 3', value: 'PS3' },
            { label: 'Xbox 360', value: '360' },
            { label: 'I cannot answer this question I love them all equally', value: 'all' },
            { label: 'I cannot answer this question I hate them all equally', value: 'no' },
          ]} />
      </React.Fragment>
    }


    <Question inputType='textarea'
      id='message'
      label='Comments'
      value={data.message}
      onChange={handleChange('message')} />

    <StyledCtaButton
      type='submit'
      disabled={isFetching}
      isLoading={isFetching}>
        { isFetching ? 'Submitting' : 'Submit' }
    </StyledCtaButton>
  </StyledRSVPForm>
  )
}

export default RSVPForm;