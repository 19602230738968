import axios from 'axios';
import { FormEvent } from 'react';
import styled from 'styled-components';
import { useForm } from '../../hooks/useForm';
import Question from '../Question/Question';
import { ICompletedFormValues } from '../RSVPForm/RSVPFormWrapper';
import { StyledCtaButton, StyledQuestion, StyledLabel, StyledTextInput, StyledSelectInput, SROnlyStyle } from '../Styles';

export const StyledFunMessageForm = styled.div`
  font-style: italic;

  ${StyledQuestion} {
    font-style: normal;
    display: inline-block;
    width: 125px;
    margin: 0 6px;
  }

  ${StyledLabel} {
    ${SROnlyStyle};
  }

  ${StyledTextInput},
  ${StyledSelectInput} {
    padding: 0;
    font-style: normal;
    font-weight: bold;
    margin: 0;
    width: 100%;

    &::placeholder {
      color: #999;
      font-weight: normal; 
    }
  }

  ${StyledCtaButton} {
    margin: 20px auto 0;
  }
`;

interface IFunMessageFormProps {
  /** Previously completed RSVP form */
  completedFormValues: ICompletedFormValues;

  /** Assumed correct passcode used from previous form */
  passcode: string;

  /** Fired when the request returns successfully */
  onSubmitSuccess: Function;

  /** Fired in instances of API returning an error */
  onError: Function;
}

interface IFunMessage {
  field0: string;
  field1: string;
  field2: string;
  field3: string;
  field4: string;
  field5: string;
  field6: string;
  field7: string;
  field8: string;
  field9: string;
  field10: string;
}

const constructMessage = (data: IFunMessage) => {
  const funMessage = `<span>${data.field0}!</span>
    I/we are so <span>${data.field1}</span> that Alicia and Paul are getting married!
    I/we knew <span>${data.field2}</span> was the one for <span>${data.field3}</span> when <span>${data.field4}</span>.
    On 29th May 2023, when they <span>${data.field5}</span> down the <span>${data.field6}</span>,
    I/we <span>${data.field7}</span> be there to <span>${data.field8}</span>. I/we wish them <span>${data.field9}</span>
    years of happiness and <span>${data.field10}!</span>  `;

  return funMessage;
}

/** Makes request to resubmit RSVP but with the fun message */
const submitFunMessage = async (data: any, passcode: string) => {
  const baseUrl = process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost/api';
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  const payload = {
    ...data,
    passcode
  };
  return await axios.post(`${baseUrl}/rsvp`, payload, config);
}

const FunMessageForm = (props: IFunMessageFormProps) => {
  const { completedFormValues, passcode, onSubmitSuccess, onError } = props;

  const { handleSubmit, handleChange, data } = useForm<IFunMessage>({
    validations: {},
    onSubmit: () => {
      const funMessage = constructMessage(data);
      completedFormValues['funMessage'] = funMessage;
      submitFunMessage(completedFormValues, passcode).then((response) => {
        if (response && response.data) {
          onSubmitSuccess(funMessage);
        } else {
          onError();
        }
      }).catch(error => {
        onError(error);
      });
    }
  });

  const handleSaveMessage = () => {
    const fauxEvent = {
      preventDefault: () => {}
    } as FormEvent<HTMLFormElement>;
    handleSubmit(fauxEvent);
  }

  return (
  <StyledFunMessageForm>
    <Question inputType='text'
      id='field0'
      label='Salutation'
      placeholder='(salutation)'
      required={true}
      value={data.field0}
      onChange={handleChange('field0')}
    />
    ! I/we are so
    <Question inputType='text'
      id='field1'
      label='Emotional adjective 1'
      placeholder='(adjective)'
      required={true}
      value={data.field1}
      onChange={handleChange('field1')}
    />
    that Alicia and Paul are getting married! I/we knew
    <Question inputType='text'
      id='field2'
      label='Name 1'
      placeholder='Alicia/Paul'
      required={true}
      value={data.field2}
      onChange={handleChange('field2')}
    />
    was the one for
    <Question inputType='text'
      id='field3'
      label='Name 2'
      placeholder='Paul/Alicia'
      required={true}
      value={data.field3}
      onChange={handleChange('field3')}
    />
    when
    
    <Question inputType='text'
      id='field4'
      label='How did you know we were the ones?'
      placeholder='(how?)'
      required={true}
      value={data.field4}
      onChange={handleChange('field4')}
    />
    . On 29th May 2023, when they
    <Question inputType='text'
      id='field5'
      label='Verb 1'
      placeholder='(verb)'
      required={true}
      value={data.field5}
      onChange={handleChange('field5')}
    />
    down the
    <Question inputType='text'
      id='field6'
      label='Noun 1'
      placeholder='(noun)'
      required={true}
      value={data.field6}
      onChange={handleChange('field6')}
    />
    , I/we,
    <Question inputType='text'
      id='field7'
      label="Will or won't"
      placeholder="(will/won't)"
      required={true}
      value={data.field7}
      onChange={handleChange('field7')}
    />
    be there to
    
    <Question inputType='text'
      id='field8'
      label='Verb 2'
      placeholder='(verb)'
      required={true}
      value={data.field8}
      onChange={handleChange('field8')}
    />
    . I/we wish them
    <Question inputType='text'
      id='field9'
      label='Number'
      placeholder='(number)'
      required={true}
      value={data.field9}
      onChange={handleChange('field9')}
    />
    years of happiness and
    <Question inputType='text'
      id='field10'
      label='Noun 3'
      placeholder='(noun)'
      required={true}
      value={data.field10}
      onChange={handleChange('field10')}
    />
    !
    <StyledCtaButton onClick={handleSaveMessage}>Submit message</StyledCtaButton>
  </StyledFunMessageForm>
  )
}

export default FunMessageForm;