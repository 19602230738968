import { IImageCollection } from '../../../../components/ImageCollection/ImageCollection';

import img_20230331_160400 from './20230331_160400_1176x2419.jpg';
import img_20230401_105034 from './20230401_105034_1176x2419.jpg';
import img_20230401_184113 from './20230401_184113_2765x2074.jpg';
import img_20230331_160711 from './20230331_160711_1176x2419.jpg';
import img_20230401_110436 from './20230401_110436_2419x1176.jpg';
import img_20230401_191859 from './20230401_191859_1267x2765.jpg';
import img_20230331_183445 from './20230331_183445_1800x2400.jpg';
import img_20230401_110528 from './20230401_110528_2419x1176.jpg';
import img_20230401_192226 from './20230401_192226_1800x2400.jpg';
import img_20230331_192314 from './20230331_192314_2400x1800.jpg';
import img_20230401_111132 from './20230401_111132_2419x1176.jpg';
import img_20230401_202734 from './20230401_202734_2765x2074.jpg';
import img_20230331_220357 from './20230331_220357_1267x2765.jpg';
import img_20230401_111344 from './20230401_111344_2419x1176.jpg';
import img_20230402_104710 from './20230402_104710_2765x2074.jpg';
import img_20230401_083156 from './20230401_083156_1267x2765.jpg';
import img_20230401_120555 from './20230401_120555_2074x2765.jpg';
import img_20230402_161729 from './20230402_161729_2074x2765.jpg';
import img_20230401_090656724 from './20230401_090656724_2189x1642.jpg';
import img_20230401_152621 from './20230401_152621_2189x1642.jpg';
import img_20230401_090801 from './20230401_090801_1176x2419.jpg';
import img_20230401_160808 from './20230401_160808_2074x2765.jpg';
import img_20230401_100326 from './20230401_100326_2765x1267.jpg';
import img_20230401_174339171 from './20230401_174339171_1843x2448.jpg';
import img_20230401_103347 from './20230401_103347_2189x1642.jpg';
import img_20230401_174455139 from './20230401_174455139_1843x2448.jpg';

const images: IImageCollection[] = [
  {
    src: img_20230331_160400,
    width: 1176,
    height: 2419
  },
  {
    src: img_20230401_105034,
    width: 1176,
    height: 2419
  },
  {
    src: img_20230401_184113,
    width: 2765,
    height: 2074
  },
  {
    src: img_20230331_160711,
    width: 1176,
    height: 2419
  },
  {
    src: img_20230401_110436,
    width: 2419,
    height: 1176
  },
  {
    src: img_20230401_191859,
    width: 1267,
    height: 2765
  },
  {
    src: img_20230331_183445,
    width: 1800,
    height: 2400
  },
  {
    src: img_20230401_110528,
    width: 2419,
    height: 1176
  },
  {
    src: img_20230401_192226,
    width: 1800,
    height: 2400
  },
  {
    src: img_20230331_192314,
    width: 2400,
    height: 1800
  },
  {
    src: img_20230401_111132,
    width: 2419,
    height: 1176
  },
  {
    src: img_20230401_202734,
    width: 2765,
    height: 2074
  },
  {
    src: img_20230331_220357,
    width: 1267,
    height: 2765
  },
  {
    src: img_20230401_111344,
    width: 2419,
    height: 1176
  },
  {
    src: img_20230402_104710,
    width: 2765,
    height: 2074
  },
  {
    src: img_20230401_083156,
    width: 1267,
    height: 2765
  },
  {
    src: img_20230401_120555,
    width: 2074,
    height: 2765
  },
  {
    src: img_20230402_161729,
    width: 2074,
    height: 2765
  },
  {
    src: img_20230401_090656724,
    width: 2189,
    height: 1642
  },
  {
    src: img_20230401_152621,
    width: 2189,
    height: 1642
  },
  {
    src: img_20230401_090801,
    width: 1176,
    height: 2419
  },
  {
    src: img_20230401_160808,
    width: 2074,
    height: 2765
  },
  {
    src: img_20230401_100326,
    width: 2765,
    height: 1267
  },
  {
    src: img_20230401_174339171,
    width: 1843,
    height: 2448
  },
  {
    src: img_20230401_103347,
    width: 2189,
    height: 1642
  },
  {
    src: img_20230401_174455139,
    width: 1843,
    height: 2448
  },
];

export default images;