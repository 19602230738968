import styled from 'styled-components';
import Hero from '../components/Hero/Hero';
import InfoSection from '../components/InfoSection/InfoSection';
import Footer from '../components/Footer/Footer';
import TaxiShareFormWrapper from '../components/TaxiShareForm/TaxiShareFormWrapper';

const StyledTaxiShare = styled.div``;

const TaxiSharePage = () => {
  return (
    <StyledTaxiShare>
      <Hero
        title='Taxi Share'
        withBackLink />
      <InfoSection>
        <TaxiShareFormWrapper />
      </InfoSection>
      <Footer />
    </StyledTaxiShare>
  )
}

export default TaxiSharePage;