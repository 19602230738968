
import React, { ChangeEvent } from 'react';
import { StyledLabel,
  StyledQuestion,
  StyledTextarea,
  StyledTextInput,
  StyledErrorMessage,
  StyledSelectInput } from '../Styles';

interface IQuestionProps {
  inputType: 'text' | 'textarea' | 'number' | 'select';
  id: string;
  label: string;
  value?: string | number;
  min?: number;
  max?: number;
  disabled?: boolean;
  validation?: string;
  onChange?: any;
  required?: boolean;
  placeholder?: string;
  errors?: string;
  /** Used for select values */
  selectOptions?: { label: string; value: string }[];
}

export interface IQuestionState {
  isTouched: boolean;
  isValid: boolean;
};

class Question extends React.Component<IQuestionProps, IQuestionState> {
  constructor(props: IQuestionProps) {
    super(props);
    this.state = {
      isTouched: false,
      isValid: false
    }
  }

  handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    // Clamp value if number type
    if (this.props.inputType === 'number' && this.props.max) {
      const targetValue = parseInt(event.target.value, 10);
      const minValue = this.props.min || 0;
      if (targetValue > this.props.max) {
        event.target.value = this.props.max.toString();
      } else if (targetValue < minValue) {
        event.target.value = minValue.toString();
      }
    }

    this.validate();

    // Bubble event up if possible
    if (this.props.onChange) {
      this.props.onChange(event, this.state);
    } 
  }

  validate = () => {
    this.setState({
      ...this.state,
      isTouched: true
    });
  }

  render () {
    const {
      inputType,
      label,
      id,
      disabled,
      required,
      placeholder,
      errors,
      value,
      selectOptions } = this.props;
    return (
      <React.Fragment>
        <StyledQuestion>
          <StyledLabel htmlFor={id}>
            {label}
            { required && ' (required)' }
          </StyledLabel>
          { inputType !== 'textarea' && inputType !== 'select' &&
            <StyledTextInput
              id={id}
              name={id}
              type={inputType === 'number' ? 'number' : 'input'}
              disabled={disabled}
              required={required}
              aria-required={required}
              placeholder={placeholder}
              value={value}
              onChange={this.handleChange} />
          }
          { inputType === 'select' &&
            <StyledSelectInput
              id={id}
              name={id}
              disabled={disabled}
              required={required}
              aria-required={required}
              placeholder={placeholder}
              value={value}
              onChange={this.handleChange}
            >
              {selectOptions?.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </StyledSelectInput>
          }
          { inputType === 'textarea' &&
            <StyledTextarea
              id={id}
              name={id}
              required={required}
              aria-required={required}
              placeholder={placeholder}
              value={value}
              onChange={this.handleChange}
              disabled={disabled} />
          }
        </StyledQuestion>
        { errors && <StyledErrorMessage>{errors}</StyledErrorMessage> }
      </React.Fragment>
    )
  }
}

export default Question;