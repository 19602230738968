import styled from 'styled-components';
import { colours, fonts } from '../Variables';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

const StyledBackLinkContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 95%;
  max-width: 900px;
  margin: 0 auto;
`;

export const StyledBackLink = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  color: ${colours.orange};
  font-weight: bold;
  font-family: ${fonts.quattro};
  line-height: 1rem;
  cursor: pointer;

  svg {
    margin-right: 4px;
  }
`;

const BackLink = () => {
  const navigate = useNavigate();
  return (
    <StyledBackLinkContainer>
      <StyledBackLink onClick={() => navigate(-1)}>
        <FontAwesomeIcon icon={faChevronLeft} />
        Back
      </StyledBackLink>
    </StyledBackLinkContainer>
  )
}

export default BackLink;