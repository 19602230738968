import axios from 'axios';
import styled from 'styled-components';
import { useForm } from '../../hooks/useForm';
import Question from '../Question/Question';
import { StyledCtaButton } from '../Styles';
import { IGuest } from './RSVPFormWrapper';

export const StyledRSVPForm = styled.form`
  width: 100%;

  ${StyledCtaButton} {
    margin: 0 auto;
  }
`;

export interface IRSVPFinderForm {
  /** Guest name */
  name: string;

  /** Simple secret code to confirm legitimacy */
  passcode: string;
}

export interface IRSVPFinderStatus {
  /** API status of the RSVP finder form */
  state: 'ready' | 'complete' | 'loading' | 'error';

  /** Additional information, if necessary (e.g. error message) */
  message?: string;
};


export interface IFindGuestsResponse {
  id: number;
  guests: IGuest[];
  receptionOnly?: boolean;
  contactEmail?: string;
  contactNumber?: string;
  console?: string;
  message?: string;
}

/** Makes request for guests */
const findGuests = async (payload: IRSVPFinderForm) => {  
  const baseUrl = process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost/api';
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };  
  return await axios.post(`${baseUrl}/guests`, payload, config);
} 

interface IRSVPFinderProps {
  /** Fired when the request has been submitted */
  onSubmitRequest: Function;

  /** Fired when the request returns successfully */
  onSubmitSuccess: Function;

  /** Fired in instances of API returning an error */
  onError: Function;

  /** Loading state of form */
  isFetching: boolean;
}

const RSVPFinder = (props: IRSVPFinderProps) => {
  const { onSubmitRequest, onSubmitSuccess, onError, isFetching } = props;

  const { handleSubmit, handleChange, errors, data } = useForm<IRSVPFinderForm>({
    validations: {
      name: {
        required: {
          value: true,
          message: 'This field is required'
        }
      },
      passcode: {
        required: {
          value: true,
          message: 'This field is required'
        }
      }
    },
    onSubmit: () => {
      onSubmitRequest();
      findGuests(data).then((response: any) => {
        if (response && response.data) {
          onSubmitSuccess(response.data, data.passcode);
        } else {
          onError();
        }
      }).catch((error) => {
        onError(error);
      });
    }
  });

  return (
    <StyledRSVPForm onSubmit={handleSubmit}>
      <Question inputType='text'
        id='name'
        label='What is your name?'
        required={true}
        value={data.name}
        placeholder='Firstname Surname'
        onChange={handleChange('name')}
        errors={errors.name} />
      <Question inputType='text'
        id='passcode'
        label='Enter secret Bakewell passcode'
        required={true}
        value={data.passcode}
        onChange={handleChange('passcode')} />
      <StyledCtaButton
        type='submit'
        disabled={isFetching}
        isLoading={isFetching}>
          { isFetching ? 'Submitting' : 'Submit' }
      </StyledCtaButton>
    </StyledRSVPForm>
  )
}

export default RSVPFinder;
