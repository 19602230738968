import axios from 'axios';
import styled from 'styled-components';
import { useForm } from '../../hooks/useForm';
import Question from '../Question/Question';
import { StyledCtaButton } from '../Styles';
import { IFindGuestsResponse } from '../RSVPForm/RSVPFinder';
import { IHotelStay } from './TaxiShareFormWrapper';
import { availableHotels } from '../../pages/HotelsPage';

export const StyledTaxiShareForm = styled.form`
  width: 100%;

  ${StyledCtaButton} {
    margin: 0 auto;
  }
`;

export interface ITaxiShareFormStatus {
  /** API status of the RSVP finder form */
  state: 'ready' | 'complete' | 'loading' | 'error';

  /** Additional information, if necessary (e.g. error message) */
  message?: string;
};

interface ITaxiShareFormProps {
  /** Fired when the request has been submitted */
  onSubmitRequest: Function;

  /** Fired when the request returns successfully */
  onSubmitSuccess: Function;

  /** Fired in instances of API returning an error */
  onError: Function;

  /** Loading state of form */
  isFetching: boolean;

  /** Assumed correct passcode used from previous form */
  passcode: string;

  /** Response from fetching the RSVP invites from the `/guests` endpoint */
  rsvp: IFindGuestsResponse;
}

/** Makes request to submit where they are staying */
const submitTaxiShare = async (data: any, passcode: string) => {
  const baseUrl = process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost/api';
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  const payload = {
    ...data,
    passcode
  };
  return await axios.post(`${baseUrl}/hotel`, payload, config);
}

const parseData = (data: IHotelStay, rsvp: any) => {  
  const parsedData: any = {
    id: rsvp.id,
    hotel: data.hotel
  };

  return parsedData;
}

const TaxiShareForm = (props: ITaxiShareFormProps) => {
  const { onSubmitRequest, onSubmitSuccess, onError, isFetching, rsvp, passcode } = props;

  const { handleSubmit, handleChange, data } = useForm<IHotelStay>({
    validations: {
      hotel: {
        required: {
          value: true,
          message: 'This field is required'
        }
      },
    },
    onSubmit: () => {
      const parsedData = parseData(data, rsvp);
      onSubmitRequest(parsedData);
      submitTaxiShare(parsedData, passcode).then((response) => {
        if (response && response.status === 200) {
          onSubmitSuccess();
        } else {
          onError();
        }
      }).catch(error => {
        onError(error);
      });
    }
  });

  return (
  <StyledTaxiShareForm onSubmit={handleSubmit}>
    <Question inputType='select'
      id='hotel'
      label='Which hotel are you staying at?'
      selectOptions={
        availableHotels
          .filter(availableHotel => availableHotel.name !== 'Milsoms')
          .map(availableHotel => ({ value: availableHotel.name, label: availableHotel.name }))
      }
      value={data.hotel}
      onChange={handleChange('hotel')} />

    <StyledCtaButton
      type='submit'
      disabled={isFetching}
      isLoading={isFetching}>
        { isFetching ? 'Submitting' : 'Submit' }
    </StyledCtaButton>
  </StyledTaxiShareForm>
  )
}

export default TaxiShareForm;