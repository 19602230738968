import styled from 'styled-components';
import Hero from '../components/Hero/Hero';
import InfoSection from '../components/InfoSection/InfoSection';
import Footer from '../components/Footer/Footer';
import CancelFormWrapper from '../components/CancelForm/CancelFormWrapper';

const StyledCancelPage = styled.div``;

const CancelPage = () => {
  return (
    <StyledCancelPage>
      <Hero
        title="Cancel"
        withBackLink />
      <InfoSection>
        <CancelFormWrapper />
      </InfoSection>
      <Footer />
    </StyledCancelPage>
  )
}

export default CancelPage;