
import styled from 'styled-components';
import Hero from '../components/Hero/Hero';
import InfoSection, { StyledInfoSectionRow } from '../components/InfoSection/InfoSection';
import InfoColumn from '../components/InfoSection/InfoColumn';
import { faTaxi } from '@fortawesome/free-solid-svg-icons'
import { StyledLink } from '../components/Styles';
import { colours } from '../components/Variables';
import Footer from '../components/Footer/Footer';

const StyledTaxis = styled.div`
  ul {
    list-style: none;
    margin: 0;

    li {
      font-size: 1.125rem;
    }
    
    a, span {
      display: block;
    }

    span {
      color: ${colours.orange};
      font-weight: bold;
    }
  }
`;

const TaxisPage = () => {
  return (
    <StyledTaxis>
      <Hero
        title='Taxis'
        withBackLink />
      <InfoSection>
        <StyledInfoSectionRow>
          <InfoColumn
            heading="Colchester"
            faIcon={faTaxi}>
            <ul>
              <li>
                <StyledLink href='http://panther-group.co.uk/'
                  target='_blank'
                  rel='noreferrer'>
                  Panther Cabs
                </StyledLink>
                <span>01206 525525</span>
              </li>
              <li>
                <StyledLink href='https://www.facebook.com/TownCars515515/'
                  target='_blank'
                  rel='noreferrer'>
                  Town Cars
                </StyledLink>
                <span>01206 515515</span>
              </li>
              <li>
                <StyledLink href='https://www.yell.com/biz/five-sevens-taxi-colchester-7291727/'
                  target='_blank'
                  rel='noreferrer'>
                  Five Sevens
                </StyledLink>
                <span>01206 577777</span>
              </li>
              <li>
                <StyledLink href='https://www.fiveeightscolchester.com/'
                  target='_blank'
                  rel='noreferrer'>
                  Five Eights
                </StyledLink>
                <span>01206 588888</span>
              </li>
            </ul>
          </InfoColumn>
          <InfoColumn
            heading="Hadleigh"
            faIcon={faTaxi}>
            <ul>
              <li>
                <StyledLink href='https://www.facebook.com/HadleighPrivateHire/'
                  target='_blank'
                  rel='noreferrer'>
                  Hadleigh Private Hire Cars
                </StyledLink>
                <span>01473 829700</span>
              </li>
              <li>
                <StyledLink href='https://5starcarshadleigh.co.uk/'
                  target='_blank'
                  rel='noreferrer'>
                  5 Star Cars
                </StyledLink>
                <span>01473 875435</span>
                <span>07501678519</span>
              </li>
            </ul>
          </InfoColumn>
          <InfoColumn
            heading="Ipswich"
            faIcon={faTaxi}>
            <ul>
              <li>
                <StyledLink href='http://ipswichcabs289.co.uk/'
                  target='_blank'
                  rel='noreferrer'>
                  Hawk Express Cabs
                </StyledLink>
                <span>01473 222222</span>
                <span>01473 289289</span>
              </li>
              <li>
                <StyledLink href='https://www.avenue-taxis.co.uk/'
                  target='_blank'
                  rel='noreferrer'>
                  Avenue Taxis
                </StyledLink>
                <span>01473 888888</span>
              </li>
            </ul>
          </InfoColumn>
        </StyledInfoSectionRow>
      </InfoSection>
      <InfoSection>
        <StyledInfoSectionRow>
          <InfoColumn
              heading="Taxi share"
              faIcon={faTaxi}
              linkText="Taxi share"
              routerLink="/taxi-share">
              <p>If you want a taxi share, let us know where you're staying and we'll try to connect you to any other guests staying at the same hotel!</p>
            </InfoColumn>
        </StyledInfoSectionRow>
      </InfoSection>
      <Footer />
    </StyledTaxis>
  )
}

export default TaxisPage;