import React from 'react';
import styled from 'styled-components';
import ContentPanel from '../components/ContentPanel/ContentPanel';
import Footer from '../components/Footer/Footer';
import Hero from '../components/Hero/Hero';
import { CtaStyle, StyledCtaLink, StyledCtaRouterLink, StyledLink } from '../components/Styles';
import leTalbooth from '../assets/images/le-talbooth-2.jpg';
import galleryPromptImage from '../assets/images/gallery/wedding/DSC_01193.jpg';
import couple3 from '../assets/images/couple-3.jpg';
import WeddingParty from '../components/WeddingParty/WeddingParty';
import Navigation from '../components/Navigation/Navigation';
import Cookies from '../components/Cookies/Cookies';
import EngagementGallery from '../components/EngagementGallery/EngagementGallery';
import carouselImages from '../assets/images/carousel/carouselImages';

const StyledHome = styled.div`
  .chq-atc {
    position: relative;

    &--button {
      ${CtaStyle};
      cursor: pointer;
      font-weight: bold;
      width: 100%;
      
      &,
      &:last-child {
        margin-bottom: 0.5rem;
      }

      svg {
        display: none;
      }
    }

    &--dropdown {
      position: absolute;
      width: 50%;
      left: 25%;
      top: 2.5rem;
      background: #ffffff;
      z-index: 1;
      border: 1px solid #999;

      &:before {
        content: '';
        position: absolute;
        left: calc(50% - 11px);
        top: -11px;
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
        transform-origin: center center;
        background: #ffffff;
        border-left: 1px solid #999;
        border-top: 1px solid #999;
      }

      a {
        position: relative;
        z-index: 1;
        display: block;
        padding: 0.25rem 10px;
      }
    }
  }
`;

interface IHomeProps {
  displayCeremony: boolean;
}
interface IHomeState {};

class HomePage extends React.Component<IHomeProps, IHomeState> {
  render () {
    return (
      <StyledHome>
        <Hero
          title='Alicia &amp; Paul'
          defaultLogo={true}>
            29.05.23<br />Bakewell Wedding
        </Hero>
        <Navigation links={[
          { to: '/gallery', label: 'Gallery', showOnMobile: true },
          { to: '/video', label: 'Video', showOnMobile: true },
          { href: '#gifts', label: 'Gifts', showOnMobile: true },
        ]} />  
        <ContentPanel
          imgSrc={galleryPromptImage}>
          <h2 style={{fontSize: '2.75rem'}}>Thank you for coming!</h2>
          <p>Thank you all so much for coming out to celebrate our big day with us! We hope you had as much fun as we did!</p>
          <p>The professional photos and videos are in, check them out now!</p>
          <StyledCtaRouterLink to='/gallery'>See the gallery!</StyledCtaRouterLink>
          <StyledCtaRouterLink to='/video'>See the video!</StyledCtaRouterLink>
          <p>If you want any photos in full resolution, please drop us a line at <StyledLink href='mailto:questions@bakewell.wedding'>questions@bakewell.wedding</StyledLink></p>
        </ContentPanel>
        <ContentPanel
          id="gifts"
          imgSrc={couple3}
          reverse={true}>
          <h2>Gifts</h2>
          <p>Celebrating with you on our wedding day is the greatest gift we could ask for! However, if you do wish to shower us with gifts, we've created a gift list with The Wedding Shop.</p>
          <StyledCtaLink href="https://www.weddingshop.com/GiftList/BakewellWedding" target="_blank" rel="noreferrer">View Bakewell Gift List</StyledCtaLink>
        </ContentPanel>
        <EngagementGallery items={[
          { image: carouselImages.carousel_2 },
          { image: carouselImages.carousel_3 },
          { image: carouselImages.carousel_4 },
          { image: carouselImages.carousel_7 },
        ]} />
        <Footer />
        <Cookies />
      </StyledHome>
    )
  }
}

export default HomePage;