import styled from 'styled-components';
import { StyledHr } from '../Styles';
import { colours } from '../Variables';

const StyledFooter = styled.div`
  padding: 5px;
  text-align: center;

  p {
    margin: 0;
  }
  a {
    color: ${colours.orange};
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <StyledHr />
      <p><a target="_blank" rel="noreferrer" href="https://www.instagram.com/explore/tags/bakewellwedding2023/">#bakewellwedding2023</a></p>
    </StyledFooter>
  )
}

export default Footer;