import styled, { css } from 'styled-components';
import { StyledLink, StyledRouterLink } from '../Styles';
import { colours, media } from '../Variables';

const StyledNavigation = styled.nav`
  /* position: sticky; */
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  
  ul {
    background: ${colours.orange};
    width: 95%;
    max-width: 900px;
    margin: 0 auto;
    padding: 10px;
    list-style: none;
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    
    li {
      margin: 0;
      font-size: 1rem;
    }

    @media ${media.mobileSmall} {
      display: block;
 
      a {
        display: block;
        text-align: center;
        padding: 0.5rem;
      }
    }
  }

  a {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
  }`;

interface IStyledNavigationItemProps {
  showOnMobile?: boolean;
};

const StyledNavigationItem = styled.li`
  ${(props: IStyledNavigationItemProps) => !props.showOnMobile && css `  
    @media ${media.tablet} {
      display: none;
    }
  `}
`;

interface ILink {
  label: string;
  href?: string;
  to?: string;
  showOnMobile?: boolean;
};

interface INavigationProps {
  links: ILink[];
};

const Navigation = ({ links }: INavigationProps ) => {
  return (
    <StyledNavigation>
      <ul>
        { links.map(({href, to, label, showOnMobile}) => (
          <StyledNavigationItem
            key={`${href}${label}`}
            showOnMobile={showOnMobile}
          >
            { to && <StyledRouterLink to={to}>{label}</StyledRouterLink> }
            { href && <StyledLink href={href}>{label}</StyledLink> }
          </StyledNavigationItem>
        ))}
      </ul>
    </StyledNavigation>
  )
}

export default Navigation;