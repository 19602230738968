import styled from 'styled-components';
import ImageCollection from '../components/ImageCollection/ImageCollection';
import Hero from '../components/Hero/Hero';
import InfoSection from '../components/InfoSection/InfoSection';
import Navigation from '../components/Navigation/Navigation';

import stagImages from '../assets/images/gallery/stag';
import henImages from '../assets/images/gallery/hen';
import engagementImages from '../assets/images/gallery/engagement';
import weddingImages from '../assets/images/gallery/wedding';

const StyledGallery = styled.div``;

const GalleryPage = () => {
  return (
    <StyledGallery>
      <Hero
        title='Gallery'
        withBackLink />
      <Navigation links={[
        { href: '#wedding', label: 'Wedding', showOnMobile: true },
        { href: '#engagement', label: 'Engagement', showOnMobile: true },
        { href: '#henParty', label: 'Hen party', showOnMobile: true },
        { href: '#stagParty', label: 'Stag party', showOnMobile: true }
      ]} />
      <InfoSection>
        <ImageCollection
          id='wedding'
          title='Wedding'
          photos={weddingImages}
          showFilter={true} />
      </InfoSection>
      <InfoSection>
        <ImageCollection
          id='engagement'
          title='Engagement'
          photos={engagementImages} />
      </InfoSection>
      <InfoSection>
        <ImageCollection
          id='henParty'
          title='Hen party'
          photos={henImages} />
      </InfoSection>
      <InfoSection>
        <ImageCollection
          id='stagParty'
          title='Stag party'
          photos={stagImages} />
      </InfoSection>
    </StyledGallery>
  )
}

export default GalleryPage;