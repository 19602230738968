import styled from 'styled-components';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { StyledCtaButton, StyledFormMessage, StyledLink } from '../Styles';
import FunMessageForm from './FunMessageForm';
import { ICompletedFormValues } from '../RSVPForm/RSVPFormWrapper';
import { colours, fonts, media } from '../Variables';

const StyledFunMessage = styled.div`
  margin-bottom: 10px;

  ${StyledCtaButton} {
    margin-left: auto;
    margin-right: auto;
  }
`;

const StyledMessage = styled.div`
  background-color: #fff;
  margin-bottom: 10px;
  border: 3px dashed ${colours.lime};
  padding: 14px 20px;
  
  p {
    font-size: 1.125rem; // 18px
    font-style: italic;
    margin-top: 0;
  }

  span {
    font-weight: bold;
    font-style: normal;
  }
`;

const StyledMessageSignOff = styled.p`
  font-family: ${fonts.cursive};
  font-weight: bold;
  text-align: right;
  
  ${StyledMessage} & {
    font-size: 1.5rem;
  }
`;

const StyledFunMessageIntro = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const StyledFunMessageOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
`;

const StyledFunMessageModal = styled.div`
  position: fixed;
  z-index: 2;
  left: calc(50% - 270px);
  top: 30%;
  background: #fff;
  border-radius: 2px;
  padding: 10px;
  width: 540px;

  @media ${media.mobile} {
    width: 94%;
    left: 3%;
    top: 10%;
  }
`;

const StyledCloseFunMessageModal = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  background: ${colours.orange};
  border: 0;
  color: #fff;
`;

interface IFunMessageProps {
  /** Values used from the already completed RSVP Form */
  completedFormValues: ICompletedFormValues;

  /** Assumed correct passcode used from previous form */
  passcode: string;
};

interface IFunMessageState {
  modalIsOpen: boolean;
  message: string;
  status: 'pending' | 'complete' | 'error';
  errorMessage?: string;
};

class FunMessage extends React.Component<IFunMessageProps, IFunMessageState> {
  constructor(props: IFunMessageProps) {
    super(props);
    this.state = {
      modalIsOpen: false,
      message: '',
      status: 'pending'
    };
  }

  handleEditMessage = () => {
    this.setState({
      ...this.state,
      modalIsOpen: true
    });
  }

  handleSaveMessage = (message: string) => {    
    this.setState({
      ...this.state,
      modalIsOpen: false,
      message
    });
  };

  handleSubmitSuccess = (message: string) => {
    this.setState({
      ...this.state,
      status: 'complete',
      modalIsOpen: false,
      message,
      errorMessage: undefined
    });
  }

  handleSubmitError = (err?: Error) => {
    this.setState({
      ...this.state,
      status: 'error',
      modalIsOpen: false,
      errorMessage: err?.message ? err.message : 'There was an error!'
    });
  }

  reset = () => {
    this.setState({
      ...this.state,
      status: 'pending',
      modalIsOpen: true,
      errorMessage: undefined
    });
  }

  closeModal = () => {
    this.setState({
      ...this.state,
      modalIsOpen: false
    });
  };

  render() {
    const { modalIsOpen, message, status, errorMessage } = this.state;
    const { completedFormValues, passcode } = this.props;
    return (
      <StyledFunMessage>
        { status === 'pending' &&
          <React.Fragment>
            <StyledFunMessageIntro>
              <p><em>psst, fancy building a silly message to throw in too?</em></p>
              <StyledCtaButton onClick={this.handleEditMessage}>You bet!</StyledCtaButton>
            </StyledFunMessageIntro>
          </React.Fragment>
        }
        { modalIsOpen &&
          <StyledFunMessageOverlay />
        }
        <CSSTransition
          in={modalIsOpen}
          timeout={300}
          classNames="modal"
          mountOnEnter
          unmountOnExit
        >
          <StyledFunMessageModal>
            <StyledCloseFunMessageModal onClick={this.closeModal}>
              <FontAwesomeIcon icon={faTimes} />
              <span className="sr-only">Close modal</span>
            </StyledCloseFunMessageModal>
            <FunMessageForm
              completedFormValues={completedFormValues}
              passcode={passcode}
              onSubmitSuccess={(message: string) => this.handleSubmitSuccess(message)}
              onError={(err?: Error) => this.handleSubmitError(err)} />
          </StyledFunMessageModal>
        </CSSTransition>
        { status === 'error' &&
          <React.Fragment>
            <StyledFormMessage state='error'>
              {errorMessage} <br />
              Don't worry, your RSVP was submitted fine! But if you keep seeing this, drop me an email at <StyledLink href='mailto:questions@bakewell.wedding'>questions@bakewell.wedding</StyledLink>
            </StyledFormMessage>
            <StyledCtaButton onClick={this.reset}>Try again?</StyledCtaButton>
          </React.Fragment>
        }
        { status === 'complete' &&
          <React.Fragment>
            <p>Thank you for your message:</p>
            <StyledMessage>
              <p dangerouslySetInnerHTML={{ __html: message }} />
              <StyledMessageSignOff>
                Sincerely
              </StyledMessageSignOff>
            </StyledMessage>
            <StyledCtaButton onClick={this.reset}>Wait, can I rewrite that?</StyledCtaButton>
          </React.Fragment>
        }
      </StyledFunMessage>
    )
  }
}

export default FunMessage;