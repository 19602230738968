import React from 'react';
import styled from 'styled-components';

interface IStyledCookiesContainerProps {
  isOpen: boolean;
};

const StyledCookiesContainer = styled.div`
  position: fixed;
  bottom: 10px;
  left: 0;
  width: 100%;
  transition: transform 1s ease-in-out;
  transform: translateY(150%);
  ${({ isOpen }: IStyledCookiesContainerProps) => isOpen && `
    transform: translateY(0);
  `}
`;

const StyledCookies = styled.div`
  position: relative;
  max-width: 800px;
  width: 90%;
  margin: 0 auto;
  background: rgba(50, 50, 50, 0.9);
  color: #fff;
  border-radius: 5px;
  padding: 10px 30px 10px 20px;
`;

const StyledContent = styled.p`
  margin: 0;
`;

const StyledClose = styled.button`
  position: absolute;
  right: 10px;
  top: 6px;
  background: transparent;
  padding: 0;
  font-size: 1rem; // 16px
  color: #fff;
  line-height: 1rem; // 16px
  border: 0;
`;

interface ICookiesProps {};

interface ICookiesState {
  isOpen: boolean;
}

class Cookies extends React.Component<ICookiesProps, ICookiesState> {
  constructor(props: ICookiesProps) {
    super(props);
    
    this.state = {
      isOpen: false
    };
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({ isOpen: true });
    }, 5000);
  }

  handleClose = () => {
    this.setState({ isOpen: false });
  }

  render() {
    const isOpen = this.state.isOpen;
    return (
      <StyledCookiesContainer isOpen={isOpen}>
        <StyledCookies>
          <StyledContent>
            You will be pleased to see that this site does not need to use cookies to improve your browsing experience, we already have tasty bakewells!
          </StyledContent>
          <StyledClose onClick={this.handleClose}>x</StyledClose>
        </StyledCookies>
      </StyledCookiesContainer>
    )
  }
}

export default Cookies;