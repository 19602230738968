import { IImageCollection } from '../../../../components/ImageCollection/ImageCollection';

import img_20230415_WA0004 from './20230415-WA0004_1134x2016.jpg'
import img_20230417_WA0187 from './20230417-WA0187_2016x1134.jpg'
import img_20230422_213918 from './20230422_213918_2016x1134.jpg'
import img_20230417_WA0021 from './20230417-WA0021_2048x1536.jpg'
import img_20230417_WA0190 from './20230417-WA0190_1134x2016.jpg'
import img_20230422_215932_201 from './20230422_215932_201_1080x1920.jpg'
import img_20230417_WA0029 from './20230417-WA0029_1500x2000.jpg'
import img_20230417_WA0198 from './20230417-WA0198_2016x1134.jpg'
import img_20230422_225314 from './20230422_225314_1368x1824.jpg'
import img_20230417_WA0030 from './20230417-WA0030_1200x1600.jpg'
import img_20230417_WA0208 from './20230417-WA0208_1368x1824.jpg'
import img_20230423_WA0001 from './20230423-WA0001_1536x2048.jpg'
import img_20230417_WA0031 from './20230417-WA0031_1500x2000.jpg'
import img_20230417_WA0216 from './20230417-WA0216_2016x1134.jpg'
import img_20230423_WA0002 from './20230423-WA0002_1536x2048.jpg'
import img_20230417_WA0032 from './20230417-WA0032_1200x1600.jpg'
import img_20230417_WA0217 from './20230417-WA0217_2016x1134.jpg'
import img_20230423_WA0012 from './20230423-WA0012_2000x1500.jpg'
import img_20230417_WA0036 from './20230417-WA0036_1200x1600.jpg'
import img_20230417_WA0218 from './20230417-WA0218_2016x1134.jpg'
import img_20230423_WA0013 from './20230423-WA0013_1525x1600.jpg'
import img_20230417_WA0040 from './20230417-WA0040_1200x1600.jpg'
import img_20230417_WA0219 from './20230417-WA0219_2016x1134.jpg'
import img_20230423_WA0018 from './20230423-WA0018_1536x2048.jpg'
import img_20230417_WA0042 from './20230417-WA0042_1320x990.jpg'
import img_20230417_WA0220 from './20230417-WA0220_1134x2016.jpg'
import img_20230423_WA0019 from './20230423-WA0019_1536x2048.jpg'
import img_20230417_WA0044 from './20230417-WA0044_1512x2016.jpg'
import img_20230417_WA0221 from './20230417-WA0221_2016x1134.jpg'
import img_20230423_WA0020 from './20230423-WA0020_1536x2048.jpg'
import img_20230417_WA0065 from './20230417-WA0065_1440x1080.jpg'
import img_20230417_WA0238 from './20230417-WA0238_1824x1368.jpg'
import img_20230423_WA0025 from './20230423-WA0025_1536x2048.jpg'
import img_20230417_WA0074 from './20230417-WA0074_1024x768.jpg'
import img_20230417_WA0245 from './20230417-WA0245_1368x1824.jpg'
import img_20230423_WA0026 from './20230423-WA0026_1536x2048.jpg'
import img_20230417_WA0075 from './20230417-WA0075_1024x768.jpg'
import img_20230418_WA0025 from './20230418-WA0025_1536x2048.jpg'
import img_20230423_WA0029 from './20230423-WA0029_1536x2048.jpg'
import img_20230417_WA0076 from './20230417-WA0076_2048x1536.jpg'
import img_20230422_213912 from './20230422_213912_2016x1134.jpg'
import img_20230423_WA0035 from './20230423-WA0035_1024x768.jpg'
import img_20230417_WA0094 from './20230417-WA0094_2048x1536.jpg'
import img_20230422_213914 from './20230422_213914_2016x1134.jpg'
import img_20230424_WA0006 from './20230424-WA0006_1536x2048.jpg'
import img_20230417_WA0119 from './20230417-WA0119_1500x2000.jpg'
import img_20230422_213915 from './20230422_213915_2016x1134.jpg'
import img_20230424_WA0010 from './20230424-WA0010_1536x2048.jpg'
import img_20230417_WA0186 from './20230417-WA0186_2016x1134.jpg'
import img_20230422_213917 from './20230422_213917_2016x1134.jpg'

const images: IImageCollection[] = [
  {
    src: img_20230415_WA0004,
    width: 1134,
    height: 2016
  },
  {
    src: img_20230417_WA0187,
    width: 2016,
    height: 1134
  },
  {
    src: img_20230422_213918,
    width: 2016,
    height: 1134
  },
  {
    src: img_20230417_WA0021,
    width: 2048,
    height: 1536
  },
  {
    src: img_20230417_WA0190,
    width: 1134,
    height: 2016
  },
  {
    src: img_20230422_215932_201,
    width: 1080,
    height: 1920
  },
  {
    src: img_20230417_WA0029,
    width: 1500,
    height: 2000
  },
  {
    src: img_20230417_WA0198,
    width: 2016,
    height: 1134
  },
  {
    src: img_20230422_225314,
    width: 1368,
    height: 1824
  },
  {
    src: img_20230417_WA0030,
    width: 1200,
    height: 1600
  },
  {
    src: img_20230417_WA0208,
    width: 1368,
    height: 1824
  },
  {
    src: img_20230423_WA0001,
    width: 1536,
    height: 2048
  },
  {
    src: img_20230417_WA0031,
    width: 1500,
    height: 2000
  },
  {
    src: img_20230417_WA0216,
    width: 2016,
    height: 1134
  },
  {
    src: img_20230423_WA0002,
    width: 1536,
    height: 2048
  },
  {
    src: img_20230417_WA0032,
    width: 1200,
    height: 1600
  },
  {
    src: img_20230417_WA0217,
    width: 2016,
    height: 1134
  },
  {
    src: img_20230423_WA0012,
    width: 2000,
    height: 1500
  },
  {
    src: img_20230417_WA0036,
    width: 1200,
    height: 1600
  },
  {
    src: img_20230417_WA0218,
    width: 2016,
    height: 1134
  },
  {
    src: img_20230423_WA0013,
    width: 1525,
    height: 1600
  },
  {
    src: img_20230417_WA0040,
    width: 1200,
    height: 1600
  },
  {
    src: img_20230417_WA0219,
    width: 2016,
    height: 1134
  },
  {
    src: img_20230423_WA0018,
    width: 1536,
    height: 2048
  },
  {
    src: img_20230417_WA0042,
    width: 1320,
    height: 990
  },
  {
    src: img_20230417_WA0220,
    width: 1134,
    height: 2016
  },
  {
    src: img_20230423_WA0019,
    width: 1536,
    height: 2048
  },
  {
    src: img_20230417_WA0044,
    width: 1512,
    height: 2016
  },
  {
    src: img_20230417_WA0221,
    width: 2016,
    height: 1134
  },
  {
    src: img_20230423_WA0020,
    width: 1536,
    height: 2048
  },
  {
    src: img_20230417_WA0065,
    width: 1440,
    height: 1080
  },
  {
    src: img_20230417_WA0238,
    width: 1824,
    height: 1368
  },
  {
    src: img_20230423_WA0025,
    width: 1536,
    height: 2048
  },
  {
    src: img_20230417_WA0074,
    width: 1024,
    height: 768
  },
  {
    src: img_20230417_WA0245,
    width: 1368,
    height: 1824
  },
  {
    src: img_20230423_WA0026,
    width: 1536,
    height: 2048
  },
  {
    src: img_20230417_WA0075,
    width: 1024,
    height: 768
  },
  {
    src: img_20230418_WA0025,
    width: 1536,
    height: 2048
  },
  {
    src: img_20230423_WA0029,
    width: 1536,
    height: 2048
  },
  {
    src: img_20230417_WA0076,
    width: 2048,
    height: 1536
  },
  {
    src: img_20230422_213912,
    width: 2016,
    height: 1134
  },
  {
    src: img_20230423_WA0035,
    width: 1024,
    height: 768
  },
  {
    src: img_20230417_WA0094,
    width: 2048,
    height: 1536
  },
  {
    src: img_20230422_213914,
    width: 2016,
    height: 1134
  },
  {
    src: img_20230424_WA0006,
    width: 1536,
    height: 2048
  },
  {
    src: img_20230417_WA0119,
    width: 1500,
    height: 2000
  },
  {
    src: img_20230422_213915,
    width: 2016,
    height: 1134
  },
  {
    src: img_20230424_WA0010,
    width: 1536,
    height: 2048
  },
  {
    src: img_20230417_WA0186,
    width: 2016,
    height: 1134
  },
  {
    src: img_20230422_213917,
    width: 2016,
    height: 1134
  },
];

export default images;