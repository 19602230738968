import styled from "styled-components";
import { StyledContentHolder } from "../Styles";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { StyledInfoColumn } from "../InfoSection/InfoColumn";

const StyledEngagementGalleryContainer = styled(StyledContentHolder)`
  padding: 0;

  .thumbs-wrapper {
    position: absolute;
    margin: 0;
    bottom: 10px;
    left: 0;
    width: 100%;

    .thumbs {
      display: flex;
      justify-content: center;

      .thumb {
        display: flex;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .slide {
    max-height: 90vh;
  }

  ${StyledInfoColumn} & {
    width: 100%;
    margin: 0;
    box-shadow: none;
  }
`;

const StyledGalleryItem = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;

  img {
    display: block;
  }
`;

export interface ICarouselItem {
  image: string;
}

interface IEngagementGallery {
  items: ICarouselItem[]
}

export const EngagementGallery = ({items}: IEngagementGallery) => {
  return (
    <StyledEngagementGalleryContainer>
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        showStatus={false}
        showIndicators={false}
        emulateTouch={true}
        interval={6000}
        transitionTime={1000}
        showThumbs={false}>
        {
          items.map((item, index) =>
            <StyledGalleryItem key={index}>
              <img src={item.image} alt='' />
            </StyledGalleryItem>
          )
        }
      </Carousel>
    </StyledEngagementGalleryContainer>
  )
}

export default EngagementGallery;