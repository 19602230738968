import styled from 'styled-components';
import Hero, { StyledHeroHeading, StyledHeroContent } from '../components/Hero/Hero';
import InfoSection, { StyledInfoSection } from '../components/InfoSection/InfoSection';
import RSVPFormWrapper from '../components/RSVPForm/RSVPFormWrapper';
import eucalyptusBorder from '../assets/images/eucalyptus-border.png';
import { colours, media } from '../components/Variables';
import { StyledBackLink } from '../components/BackLink/BackLink';

const StyledRSVP = styled.div`
  ${StyledInfoSection} {
    max-width: 600px;
    padding-top: 0;
    padding-bottom: 20px;
    position: relative;
    color: ${colours.orange};
    background: url(${eucalyptusBorder}) repeat-y 114% top;
    background-size: 140px auto;
  }

  ${StyledBackLink} {
    background: ${colours.orange};
    color: #fff;
    padding: 10px;
  }

  ${StyledHeroHeading} {
    font-size: 2.375rem; // 38px 
  }

  @media ${media.mobile} {
    ${StyledInfoSection} {
      background-position: 105% top;
      background-size: 70px auto;
      padding-right: 100px;
    }

    ${StyledHeroContent} {
      width: 250px;
      height: 250px;
    }
    ${StyledHeroHeading} {
      font-size: 2rem; // 32px 
    }
  }
`;

const RSVPPage = () => {
  return (
    <StyledRSVP>
      <InfoSection>
        <Hero
          title='RSVP'
          withBackLink />
        <RSVPFormWrapper />
      </InfoSection>
    </StyledRSVP>
  );
}

export default RSVPPage;