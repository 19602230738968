
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar, faStar, faCaretDown, faCaretUp, faTaxi, faWalking } from '@fortawesome/free-solid-svg-icons'
import Hero from '../components/Hero/Hero';
import InfoSection, { StyledInfoSectionRow } from '../components/InfoSection/InfoSection';
import ContentPanel from '../components/ContentPanel/ContentPanel';
import { colours, media } from '../components/Variables';
import { StyledCtaLink } from '../components/Styles';
import hotelImages from '../assets/images/hotels';
import Footer from '../components/Footer/Footer';
import InfoColumn from '../components/InfoSection/InfoColumn';

const StyledHotels = styled.div`
  .hotel-warning {
    color: #c94369;
  }
`;

const StyledHotelList = styled.ul`
  list-style: none;
  padding: 0;
`;

const StyledSort = styled.fieldset`
  padding: 0;
  margin: 0 0 12px;
  border: 0;

  legend {
    padding: 0;
    display: block;
    margin-bottom: 12px;
  }

  label {
    padding: 10px;
    border: 1px solid #000;
    text-transform: uppercase;
    font-size: 0.938em;
    letter-spacing: 1px;
    margin-right: 5px;

    svg {
      margin-left: 5px;
    }
  }
  
  input:checked + label {
    background: ${colours.orange};
    color: #fff;
    border-color: transparent;    
  }

  @media ${media.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

const StyledPrice = styled.p`
  span {
    display: block;
  }
`;

const StyledStars = styled.div`
  text-align: center;
  svg {
    display: inline-block;
  }
`;

interface IHotel {
  /** Name of the hotel */
  name: string;

  /** Distance of hotel from venue (in minutes' drive) */
  distance: number;

  /** Distance of hotel from venue (in minutes' walk) */
  walkDistance?: number;

  /** Rough lowest price of hotel per room per night */
  price: number;

  /** Additional notes about the pricing (e.g. what it covers) */
  priceNote?: string;

  /** URL to hotel website */
  url: string;

  /** Path to image representing the hotel */
  image: string;

  /** Luxury rating of hotel */
  stars?: number;

  /** Additional notes for the hotel */
  notes?: string;
}

interface IHotelsProps {
  children: React.ReactNode;
}

interface IHotelsState {
  sort: string;
  ascending: boolean;
}

export const availableHotels: IHotel[] = [
  {
    distance: 1,
    name: 'Milsoms',
    price: 170,
    url: 'https://www.milsomhotels.com/milsoms/accommodation/',
    notes: `This is the closest hotel to the venue with transport available to and from the venue.
      <br /><br />
      Whilst you can't check yourselves in before 3pm, you can check in your luggage at the hotel,
      and they will take it to your room for you once it's ready!
      <br /><br />
      <span class="hotel-warning">Unfortunately the hotel is fully booked on
      the 27th May.</span>`,
    image: hotelImages.milsoms,
    stars: 4
  },
  {
    distance: 3,
    name: 'The Marlborough Dedham',
    price: 150,
    url: 'https://www.themarlboroughdedham.co.uk/',
    notes: `<span class="hotel-warning">Unfortunately this hotel is fully booked on the 27th, 28th and 29th May</span>`,
    image: hotelImages.marlborough,
    stars: 3
  },
  {
    distance: 6,
    name: 'Premier Inn Colchester',
    price: 76,
    url: 'https://www.premierinn.com/gb/en/hotels/england/essex/colchester/colchester-a12.html',
    notes: `<span class="hotel-warning">Unfortunately this hotel is fully booked on the 27th and 28th May</span>`,
    image: hotelImages.premierInnColchester,
    stars: 3
  },
  {
    distance: 7,
    name: 'Dragonfly Hotel',
    price: 67.15,
    url: 'https://www.suryahotels.co.uk/dragonflycolchester/',
    image: hotelImages.dragonfly,
    stars: 3
  },
  {
    distance: 10,
    name: 'The Crown Stoke by Nayland',
    price: 96,
    url: 'https://www.crowninn.net/',
    image: hotelImages.crown,
    notes: `<span class="hotel-warning">Unfortunately this hotel is fully booked on the 27th May</span>`,
    stars: 4
  },
  {
    distance: 10,
    name: 'Best Western, The Rose & Crown',
    price: 67.55,
    url: 'https://www.bestwestern.co.uk/hotels/best-western-the-rose-crown-hotel-83744',
    notes: `<span class="hotel-warning">Unfortunately this hotel is fully booked on the 27th and 28th May</span>`,
    image: hotelImages.roseAndCrown,
    stars: 3
  },
  {
    distance: 11,
    name: 'GreyFriars',
    price: 110,
    url: 'https://greyfriarscolchester.co.uk/',
    image: hotelImages.greyFriars,
    stars: 4
  },
  {
    distance: 15,
    name: 'Stoke by Nayland Hotel',
    price: 130,
    url: 'https://stokebynayland.com/',
    image: hotelImages.stokeByNayland,
    notes: `<span class="hotel-warning">Unfortunately this hotel is fully booked on the 28th May</span>`,
    stars: 4
  },
  {
    distance: 2,
    name: 'Talbooth House & Spa',
    price: 315,
    url: 'https://www.milsomhotels.com/talbooth-house-spa/',
    notes: `If you fancy going all out, you can stay at one of the luxury hotels in the Milsom collection, with tennis court, pool and spa!
      Similar to Milsoms, there is transport available to and from the venue.<br /><br />
      <span class="hotel-warning">Unfortunately this hotel is fully booked on the 27th, 28th and 29th May</span>`,
    image: hotelImages.talboothHouseAndSpa,
    stars: 4
  },
];

class HotelsPage extends React.Component<IHotelsProps, IHotelsState> {
  constructor(props: IHotelsProps) {
    super(props);
    
    this.state = {
      sort: 'distance',
      ascending: true
    };
  }
  
  /**
   * Update the prop to sort the hotels by
   * @param value the prop value to sort against 
  */
  setSort(value: string) {
    let newAscending = true;
    if (value === this.state.sort) {
      newAscending = !this.state.ascending;
    };
    this.setState({
      sort: value,
      ascending: newAscending
    });
  }

  render() {
    return (
    <StyledHotels>
      <Hero
        title='Hotels'
        withBackLink />
      <InfoSection>
        <StyledSort>
          <legend>Sort by:</legend>
          <input id='sortByDistance'
            className='sr-only'
            type='radio'
            name='sort'
            checked={this.state.sort === 'distance'}
            onClick={() => this.setSort('distance')} />
          <label htmlFor='sortByDistance'>
            Distance from venue
            { this.state.sort === 'distance' && this.state.ascending && <FontAwesomeIcon icon={faCaretDown} /> }
            { this.state.sort === 'distance' && !this.state.ascending && <FontAwesomeIcon icon={faCaretUp} /> }
          </label>
          <input id='sortByName'
            className='sr-only'
            type='radio'
            name='sort'
            checked={this.state.sort === 'name'}
            onClick={() => this.setSort('name')} />
          <label htmlFor='sortByName'>
            Name
            { this.state.sort === 'name' && this.state.ascending && <FontAwesomeIcon icon={faCaretDown} /> }
            { this.state.sort === 'name' && !this.state.ascending && <FontAwesomeIcon icon={faCaretUp} /> }
          </label>
          <input id='sortByPrice'
            className='sr-only'
            type='radio'
            name='sort'
            checked={this.state.sort === 'price'}
            onClick={() => this.setSort('price')} />
          <label htmlFor='sortByPrice'>
            Price
            { this.state.sort === 'price' && this.state.ascending && <FontAwesomeIcon icon={faCaretDown} /> }
            { this.state.sort === 'price' && !this.state.ascending && <FontAwesomeIcon icon={faCaretUp} /> }
          </label>
        </StyledSort>
      </InfoSection>
      <StyledHotelList>
        {
          availableHotels
            .sort((a: any, b: any) => {
              if (this.state.sort === 'name') {
                return this.state.ascending ? 
                  a[this.state.sort].localeCompare(b[this.state.sort]) :
                  b[this.state.sort].localeCompare(a[this.state.sort]);
              }
              
              if (this.state.ascending) {
                return a[this.state.sort] > b[this.state.sort] ? 1 : -1;
              } else {
                return b[this.state.sort] > a[this.state.sort] ? 1 : -1;
              }
            })
            .map((hotel, index) => 
              <li key={index}> 
                <ContentPanel
                  reverse={index % 2 ? true: false}
                  imgSrc={hotel.image ? hotel.image : 'https://loremflickr.com/512/512/hotel'}>
                  <h2>
                      {hotel.name}
                  </h2>
                  {
                    hotel.stars && 
                    <StyledStars title={hotel.stars + '-star hotel'}>
                      {
                        [...Array(hotel.stars)].map((e, starIndex) => <FontAwesomeIcon icon={faStar} key={starIndex} />)
                      }
                      <span className='sr-only'>{hotel.stars}-star hotel</span>
                    </StyledStars>
                  }
                  <StyledPrice>
                    From £{hotel.price} per night
                  { hotel.priceNote &&
                    <span>{hotel.priceNote}</span>
                  }
                  </StyledPrice>
                  { hotel.walkDistance ?
                    <p><FontAwesomeIcon icon={faWalking} /> {hotel.walkDistance}min walk</p>
                    :
                    <p><FontAwesomeIcon icon={faCar} /> {hotel.distance}min drive</p>
                  }
                  {
                    hotel.notes && <p dangerouslySetInnerHTML={{__html: hotel.notes}} />
                  }
                  <StyledCtaLink href={hotel.url} target="_blank" rel="noreferrer">View {hotel.name} website</StyledCtaLink>
                </ContentPanel>
              </li>
            )
          }
        </StyledHotelList>
      <InfoSection>
        <StyledInfoSectionRow>
          <InfoColumn
              heading="Taxi share"
              faIcon={faTaxi}
              linkText="Taxi share"
              routerLink="/taxi-share">
              <p>If you want a taxi share, let us know where you're staying and we'll try to connect you to any other guests staying at the same hotel!</p>
            </InfoColumn>
        </StyledInfoSectionRow>
      </InfoSection>
      <Footer />
    </StyledHotels>
    )
  }
}

export default HotelsPage;