import styled, { css } from 'styled-components';
import monogram from '../../assets/images/monogram-2-blank.png';
import monogramDefault from '../../assets/images/monogram-2.png';
import { colours, media } from '../Variables';
import BackLink from '../BackLink/BackLink';
import { SROnlyStyle } from '../Styles';

export const StyledHeroContainer = styled.div`
  padding: 20px 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

interface IStyledHeroContent {
  defaultLogo?: boolean;
}

export const StyledHeroContent = styled.div`
  background: url(${monogram}) no-repeat center;
  background-size: 100% auto;
  width: 300px;
  height: 300px;
  justify-content: center;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 60px;
  text-align: center;

  ${(props: IStyledHeroContent) => props.defaultLogo && css`
    background-image: url(${monogramDefault});
  `};
`;

interface IStyledHeroHeading {
  hideTitle?: boolean;
}

export const StyledHeroHeading = styled.h1`
  margin: 0 auto;
  font-size: 3rem; // 48px
  letter-spacing: 1.5px;
  display: block;
  color: ${colours.orange};
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  ${(props: IStyledHeroHeading) => props.hideTitle && css`
    ${SROnlyStyle};
  `};
`;

const StyledHeroSubtitle = styled.p`
  font-weight: bold;
  letter-spacing: 5.5px;
  margin: -0.75rem 0 0; // 12px
  text-align: center;
  color: ${colours.orange};

  @media ${media.mobile} {
    letter-spacing: 3px;
  }
`;

interface IHeroProps {
  title: string;
  defaultLogo?: boolean;
  withBackLink?: boolean;
  children?: React.ReactNode;
}

export const Hero = ({ title, defaultLogo, withBackLink, children }: IHeroProps) => {
  return (
    <StyledHeroContainer>
      { withBackLink && 
        <BackLink />
      }
      <StyledHeroContent defaultLogo={defaultLogo}>
        <StyledHeroHeading hideTitle={defaultLogo}>{title}</StyledHeroHeading>
      </StyledHeroContent>
        {children && 
          <StyledHeroSubtitle>{children}</StyledHeroSubtitle>
        }
    </StyledHeroContainer>
  )
}

export default Hero;