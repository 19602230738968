import styled from 'styled-components';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import HomePage from './pages/HomePage';
import HotelsPage from './pages/HotelsPage';
import TaxisPage from './pages/TaxisPage';
import RSVPPage from './pages/RSVPPage';
import TaxiSharePage from './pages/TaxisSharePage';
import GalleryPage from './pages/GalleryPage';
import VideoPage from './pages/VideoPage';
import CancelPage from './pages/CancelPage';

const StyledPageWrapper = styled.div`
  padding-bottom: 20px;
`;

function App() {
  return (
    <StyledPageWrapper>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path='/gallery' element={<GalleryPage />} />
          <Route path='/video' element={<VideoPage />} />
          <Route path='/hotels' element={<HotelsPage />} />
          <Route path='/rsvp' element={<RSVPPage />} />
          <Route path='/taxis' element={<TaxisPage />} />
          <Route path='/taxi-share' element={<TaxiSharePage />} />
          <Route path='/cancel' element={<CancelPage />} />
          <Route path='/' element={<HomePage />} />
        </Routes>
      </BrowserRouter>
    </StyledPageWrapper>
  );
}

export default App;
