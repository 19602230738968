export const media = {
  tablet: `only screen and (max-width: 56.25rem)`, // 900px
  mobile: `only screen and (max-width: 43.75rem)`, // 700px
  mobileSmall: `only screen and (max-width: 30rem)`, // 480px
  short: `only screen and (max-width: 48rem), (max-height: 30rem)` // 768px width, or 480px height
}

export const fonts = {
  cursive: `'brittany_signatureregular', cursive, sans-serif;`,
  crimson: `'crimson_proextralight', 'Times New Roman', Times, serif`,
  quattro: `'Quattrocento', serif`
}

export const colours = {
  green: '#255545',
  lime: '#a9d18e',
  orange: '#ed7d31',
  fuschia: '#c94369',
}