
import styled from 'styled-components';
import { Player } from 'video-react';

import Hero from '../components/Hero/Hero';
import Footer from '../components/Footer/Footer';
import { StyledContentHolder } from '../components/Styles';

import highlightsPoster from '../assets/images/gallery/wedding/DSC_01193.jpg';
import highlightsVideo from '../assets/video/highlights.mp4';

const StyledVideoPage = styled.div``;

const VideoPage = () => {
  return (
    <StyledVideoPage>
      <Hero
        title='Video'
        withBackLink />
      <StyledContentHolder>
        <Player
          playsInline
          preload='metadata'
          poster={highlightsPoster}
          src={highlightsVideo}
        />
      </StyledContentHolder>
      <Footer />
    </StyledVideoPage>
  )
}

export default VideoPage;