import styled from 'styled-components';
import { StyledContentHolder } from '../Styles';
import { media } from '../Variables';

interface IStyledContentPanelProps {
  reverse?: boolean;
}

const StyledContentPanel = styled(StyledContentHolder)`
  position: relative;
  display: flex;
  flex-direction: ${(props: IStyledContentPanelProps) => props.reverse ? 'row-reverse' : 'row'};
  align-items: stretch;
  padding: 0;

  @media ${media.mobile} {
    flex-direction: column-reverse;
  }
`;

const StyledContentPanelContent = styled.div`
  width: 50%;
  padding: 25px 50px;

  .note {
    text-align: left;
    font-size: 0.875em; // 14px
  }

  @media ${media.mobile} {
    width: 100%;
    padding: 20px;
  }
`;

interface IStyledContentPanelImageProps {
  imgSrc: string;
}

const StyledContentPanelImage = styled.div`
  width: 50%;
  background-image: url(${(props: IStyledContentPanelImageProps) => props.imgSrc});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media ${media.mobile} {
    height: 50vh;
    width: 100%;
  }
`;

interface IContentPanel {
  children: React.ReactNode;
  imgSrc: string;
  id?: string;
  reverse?: boolean;
}

const ContentPanel = ({children, imgSrc, id, reverse}: IContentPanel) => {
  return (
    <StyledContentPanel id={id} reverse={reverse}>
      <StyledContentPanelContent>
        {children}
      </StyledContentPanelContent>
      { imgSrc && 
        <StyledContentPanelImage imgSrc={imgSrc}>
        </StyledContentPanelImage>
      }
    </StyledContentPanel>
  )
}

export default ContentPanel;